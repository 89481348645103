import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Collapse, Flex, Row, Typography } from "antd";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

export default function freelanceServices() {
  const cards = [
    {
      title: "Graphic & Design",
      icon: "solar:ruler-broken",
    },
    {
      title: "Digital Marketing",
      icon: "streamline:annoncement-megaphone",
    },
    {
      title: "Writing & Translation",
      icon: "solar:pen-linear",
    },
    {
      title: "Video & Animation",
      icon: "mingcute:movie-line",
    },
    {
      title: "Music & Audio",
      icon: "ic:outline-queue-music",
    },
    {
      title: "Logo Design & Branding",
      icon: "solar:star-line-duotone",
    },
    {
      title: "Programming & Tech",
      icon: "solar:code-bold",
    },
    {
      title: "Business & Development",
      icon: "solar:card-broken",
    },
    {
      title: "Data & Analytics",
      icon: "hugeicons:analytics-02",
    },
  ];

  const benefits = [
    {
      title: "Build Customer Loyalty",
      description:
        "Work with marketing and branding experts to grow and maintain a loyal customer base.",
    },
    {
      title: "Maintain Financial Flexibility",
      description:
        "Plan for the services you need and browse through Fiverr’s marketplace of experts to find one who works within your budget.",
    },
    {
      title: "Find Work-Life Balance",
      description:
        "You can trust Fiverr’s hand-picked experts to deliver high-quality projects and take some of the burden off of you.",
    },
  ];

  return (
    <Wrapper>

    <div style={{ marginTop: "20px" }}>
      <img src={"./assets/images/img27.svg"} style={{ marginBottom: "30px" }} />
      <Title level={4} style={{ fontWeight: "bolder" }}>
        Find Freelancers Who Help Make Business Happen
      </Title>
      <Paragraph style={{ fontSize: "15px", opacity: 0.8 }}>
        We’ve partnered with Fiverr to help you hire talent from the largest
        freelance marketplace for digital services. Find out how far business
        can go when you have the right people.
      </Paragraph>
      <Title level={4} style={{ fontWeight: "bolder" }}>
        Discover Experts For All Your Business Needs
      </Title>
      <Paragraph style={{ fontSize: "15px", opacity: 0.8 }}>
        Fiverr vouches for every freelancer in their network, so you can trust
        that you’ll get great results on every project. Find top talent that
        works with your business and your budget.
      </Paragraph>

      <div style={{ marginBottom: "20px" }}>
        <Row className="cus-card-box" gutter={[25, 25]}>
          {cards?.map((item, i) => {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                className=""
                key={i}
                style={{
                  marginBottom: "20px",
                }}
              >
                <Card hoverable>
                  <div className="icon">
                    <Icon icon={item.icon} width={28} color="#013B6A"></Icon>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                    }}
                    >
                    {item.title}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <Title level={4} style={{ fontWeight: "bolder" }}>
        The Benefits of Working With Fiverr
      </Title>
      <Paragraph style={{ fontSize: "15px", opacity: 0.8 }}>
        Fiverr is for businesses that mean business. Set your company up for
        success with trusted freelancers who can deliver the services you need
        to thrive.
      </Paragraph>
      <div style={{ marginBottom: "30px", marginTop: "30px" }}>
        <Row className="cus-card-box" gutter={[25, 25]}>
          {benefits?.map((item, i) => {
            return (
              <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
                className=""
                key={i}
                style={{
                  marginBottom: "20px",
                }}
              >
                <Card hoverable >
                  <div className="icon">
                    <Icon
                      icon={"solar:check-circle-broken"}
                      width={28}
                      color="#013B6A"
                    ></Icon>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      marginBottom: "10px",
                    }}
                  >
                    {item.title}
                  </div>
                  <Paragraph style={{ color: "#999" }}>
                    {item.description}
                  </Paragraph>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card
            className="box-info "
            style={{
              overflow: "hidden",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
            >
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title className="mb-0" level={3}>
                  See what others have to say
                </Title>
                <div style={{ display: "flex", marginTop: "30px" }}>
                  <img
                    src={"./assets/images/blank.png"}
                    style={{
                      height: "50px",
                      borderRadius: "10px",
                    }}
                    />
                  <div style={{ marginLeft: "10px" }}>
                    <span style={{ fontWeight: "600", fontSize: "17px" }}>
                      Talia Sari Wiener
                    </span>
                    <br />
                    <img src={"./assets/images/img30.svg"} />
                  </div>
                </div>

                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".9", fontSize: "15px" }}>
                  I’m always blown away by the selection of services and
                  phenomenal talent that can be found on Fiverr. I can’t wait to
                  discover how Fiverr will help me grow my business in the
                  future.
                </Paragraph>
                <span
                  style={{ fontSize: "18px", fontWeight: "500", opacity: 0.9 }}
                  >
                  ceo, founder, talia sari
                </span>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items-center main-img">
                <img
                  src={"./assets/images/img31.png"}
                 
                  />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mb-7" gutter={[20,20]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"}>
              <Col xs={24} sm={24} md={12} lg={18}>
                <div>
                  <Title level={4} style={{ fontWeight: "bolder" }}>
                    Ready To Get Down to Business?
                  </Title>
                  <Paragraph style={{ opacity: 0.7, fontSize: "15px" }}>
                    Browse through Fiverr’s marketplace of freelancers — top
                    talent that can help you grow your business and stay within
                    your budget.
                  </Paragraph>
                </div>
              </Col>
              <Col  xs={24} sm={24} md={12} lg={6} className="d-flex justify-content-end  align-items-center">
                <Button size="large" type="primary">
                  EXPLORE SERVICES
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
                  </Wrapper>
  );
}
