export const UserMenu = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: "solar:layers-minimalistic-bold-duotone",
  },
  {
    key: "recommendations",
    label: "Recommendations",
    icon: "solar:chat-line-bold-duotone",
  },
  {
    key: "settings",
    label: "Settings",
    icon: "solar:settings-bold-duotone",
  },
  {
    key: "virtual-mailbox/overview",
    label: "Virtual Mailbox Overview",
    icon: "solar:inbox-line-bold-duotone",
  },
  {
    key: "virtual-mailbox",
    label: "Virtual Mailbox",
    icon: "solar:inbox-line-bold-duotone",
  },
  {
    key: "all-orders",
    label: "All Orders",
    icon: "solar:cart-5-bold-duotone",
  },
  {
    key: "completed-documents",
    label: "Completed Documents",
    icon: "solar:document-add-bold-duotone",
  },

  {
    key: "business-contract-templates",
    label: "Business Contract templates",
    icon: "solar:file-text-bold-duotone",
  },
  {
    key: "company-info",
    label: "Company Info",
    icon: "solar:buildings-3-bold-duotone",
  },
  {
    key: "registered-agent",
    label: "Registered Agent",
    icon: "solar:user-check-rounded-bold-duotone",
  },
  {
    key: "compliance",
    label: "Compliance",
    icon: "solar:hand-money-bold-duotone",
  },
  {
    key: "state-irs",
    label: "State & IRS Filings",
    icon: "solar:hand-money-bold-duotone",
  },
  {
    key: "domain-email",
    label: "Domain & Email",
    icon: "ic:baseline-email",
  },
  {
    key: "website",
    label: "Website",
    icon: "fluent-mdl2:website",
  },
  {
    key: "logo",
    label: "Logo",
    icon: "solar:star-line-duotone",
  },
  {
    key: "taxes",
    label: "Taxes",
    icon: "iconoir:percentage-circle",
  },
  {
    key: "legal",
    label: "Legal",
    icon: "uit:shield-check",
  },
  {
    key: "business-insurance",
    label: "Business Insurance",
    icon: "material-symbols-light:shield-outline-rounded",
  },
  {
    key: "financial",
    label: "Financial",
    icon: "solar:dollar-line-duotone",
  },
  {
    key: "google-my-business",
    label: "Google My Business",
    icon: "arcticons:google-my-business",
  },
  {
    key: "business-formation-kit",
    label: "Business Formation Kit",
    icon: "mingcute:basket-line",
  },
  {
    key: "business-listing",
    label: "Business Listings",
    icon: "solar:map-linear",
  },
  {
    key: "freelance-services",
    label: "Freelance Services",
    icon: "ep:brush",
  },
  {
    key: "payments",
    label: "Accept Payments",
    icon: "solar:card-broken",
  },
  // {
  //   key: "application-logs",
  //   label: "Application Logs",
  //   icon:'solar:chart-bold-duotone',
  //   children: [
  //     {
  //       key: "application-logs/central-log", label: "Central Log"
  //     },
  //     {
  //       key: "application-logs/system-error-log", label: "System Error Log"
  //     }
  //   ],
  // },
];
