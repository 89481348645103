import React, { useState } from 'react'
import { Alert, Avatar, Button, Card, Col, Flex, List, Radio, Row, Select, Typography } from 'antd'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { primaryColor } from '../../../../config';
import { WrapperInner } from './style';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;
const { Option } = Select;
const { Meta } = Card;

  
  const notices = [
    {
      key: 1,
      icon:<Icon width={27} color={primaryColor} icon='solar:shield-check-line-duotone'></Icon>,
      title: "Maintain business compliance.",
      description: "Trusted by over 1,00,000 business owners to maintain their state's business compliance obligations.",
    },
    {
      key: 2,
      icon:<Icon width={27} color={primaryColor} icon='solar:dollar-minimalistic-line-duotone'></Icon>,
      title: "Tax savings benefit",
      description: "This is a fully deductible business expense.",
    },

  ];


function OrderRegisterAgent() {

    const [selectedState, setSelectedState] = useState(null);
  const [changeAgent, setChangeAgent] = useState('yes');

console.log(changeAgent, 'changeAgent')

  return (
    <WrapperInner>
        <Link to='/registered-agent'>
            <Button color='default' className='px-0' size='large' variant='text' icon={<Icon icon='solar:arrow-left-linear' width={20}></Icon>}> Back to Registered Agent</Button>
        </Link>

        <Row className='mt-5' gutter={[26,16]}>
            <Col  xs={24} sm={24} md={7} lg={7} >
            <Card style={{position:'sticky', top:100}}>
              <Flex align='center' justify='space-between' className='mb-7 px-5 py-3' style={{background:'rgb(18 95 158 / 12%)',border:'1px dashed #0f5d9c75', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius:'10px'}} wrap>
                <Title className='  mb-0 text-center' level={5}>Order Summary</Title>
                <Title className='  mb-0 mt-0 text-center fw-bolder' style={{color:primaryColor}} level={3}>$119</Title>
              </Flex>
              {changeAgent === 'yes' && (
                <Flex className='mt-4' align='center' justify="space-between">
                <div className='fs-7'>State Fee</div>
                <div className='fs-7 fw-bolder'>$0</div>
            </Flex>
        )}
             
                <Flex className='mt-4' align='center' justify="space-between">
                    <div className='fs-7'>Registered Agent Fee</div>
                    <div className='fs-7 fw-bolder'>$119</div>
                 </Flex>
                <div className='seprator my-3'></div>
                 <Flex className='' align='center' justify="space-between">
                    <div className='fs-7'>Registered Agent Fee</div>
                    <div className='fs-7 fw-bolder'>$119</div>
                 </Flex>

                 <div className='seprator my-3'></div>


              <List
            dataSource={notices}
            className="mt-5"
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.icon}
                  title={<Text strong>{item.title}</Text>}
                  description={item.description}
                />
              </List.Item>
            )}
          />
            </Card>
            </Col>


            <Col xs={24} sm={24} md={16} lg={16}>

            <Card className='mb-5' title={<><div style={{fontWeight:400,fontSize:12,}}>Current associate company:</div><div>IYSHIPPING LLC</div></>}>

                <Row style={{alignItems:'center'}} gutter={[26,16]}>
                    <Col  xs={24} sm={24} md={12} lg={6}> 
                    Entity Type: <Text strong>LLC</Text> 
                    </Col>
                    <Col  xs={24} sm={24} md={12} lg={6}>
                    State of Formation: <Text strong>Illinois</Text> 
                    </Col>
                    <Col className='d-flex align-items-center  justify-content-between' xs={24} sm={24} md={12} lg={12}>
                    <Text strong>State Requiring RA:</Text> 
                    <Select
                    placeholder="Select State"
                    style={{ width: 150, marginLeft: 10 }}
                    onChange={value => setSelectedState(value)}
                    >
                    <Option value="california">California</Option>
                    <Option value="texas">Texas</Option>
                    </Select>
                    </Col>
                </Row>
                <Card className='box-info mt-5'>
            <Flex align="center" justify="space-between">
              <Meta
              className=' d-flex align-items-center'
                avatar={
                  <Avatar
                    style={{ backgroundColor: "rgb(218 230 241)" }}
                    size={55}
                    icon={
                      <Icon
                        icon="solar:lightbulb-line-duotone"
                        style={{ color: primaryColor }}
                        width={27}
                      ></Icon>
                    }
                  />
                }
                // title="Virtual Mailbox and Mail Scanning Service"
                description={
                  <span className='' >
                  The <b style={{color:'#000'}}>state of formation</b> is where the company was formed, while the <b style={{color:'#000'}}>state of service</b> is where you are seeking to obtain authority to transact business.
                  </span>
                }
              />
              <>
                
              </>
            </Flex>
                </Card>

            </Card>


            <Card title="Change Agent">
                <div className='mb-4'>Would you like us to facilitate the transfer of Registered Agent service from your current provider to us?</div>

                <Radio.Group onChange={e => setChangeAgent(e.target.value)}>
                    <Radio className='rdi-btn' value={"yes"}>Yes</Radio>
                    <Radio className='rdi-btn' value={"no"}>No</Radio>
                </Radio.Group>

        {changeAgent === 'yes' && (
          <Alert
          className='mb-0 mt-4 p-3'
          description="Select No, if entity has not been filled."
          type="error"
          />
        )}
        
        <div className='mt-4'>Please select agent service, if you would like us to facilitate the transfer of registered agent service for you.</div>

                <Card className='box-info mt-5'>
                    <Meta
                        className=' d-flex align-items-center'
                            avatar={
                                <Avatar style={{ backgroundColor: "rgb(218 230 241)" }} size={55} icon={<Icon icon="solar:lightbulb-line-duotone" style={{ color: primaryColor }}  width={27} ></Icon> } />
                                }
                            // title="Virtual Mailbox and Mail Scanning Service"
                            description={
                            <span className='' >
                            If the entity has a current registered agent on record it is mandatory that the designated forms to transfer the service to the new registered agent are filed. Otherwise, the state will not recognize that a new registered agent has been assigned. As a courtesy, we offer our clients the option to have us file the required documents on their behalf.
                            </span>
                            }
                        />
            
                </Card>


            </Card>
          </Col>

  
        </Row>
    </WrapperInner>
  )
}

export default OrderRegisterAgent