import styled from "styled-components";

export const Wrapper = styled.div`
.main-img img{
height: 380px;
}

.domain-name{
width: 450px;
text-align: center;
position: absolute;
background: white;
box-shadow: 0px 4px 20px rgba(0,0,0,0.1);
border-radius: 12px;
padding: 45px;
left: 33%;
top:25%;
z-index: 1;
}


@media  (min-width: 992px) and (max-width: 1199px) {
    .main-img img{
height: 100%;
width:100%;
}
.domain-name{
left: 19%;
}
}


@media  (min-width: 768px) and (max-width: 991px) {
  .main-img img{
height: 100%;
width:100%;
}
.domain-name{
        left: 8%;
        width: 373px;
    
}
}

@media (max-width: 767px) {
.main-img{
jusify-content:center;
}
.main-img img{
height: 100%;
width:100%;
}
.domain-name{
        left: 22%;
        width: 373px;
    
}
}
@media (max-width: 546px) {
.domain-name{
      left: 17%;
        width: 191px;
        top: 26%;
    padding:14px;
    
}
}
`;