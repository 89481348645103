import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Card,
  Col,
  Collapse,
  Flex,
  Layout,
  message,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { Wrapper } from "./style";

const { Option } = Select;

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const faqs = [
  {
    question: "Is the package price ongoing or a one-time fee?",
    answer:
      "The prices referenced in all of our formation packages are a one-time fee unlike some of our more unsavory competitors our goal is not to trap our clients into a perpetual never-ending subscription but rather to provide fair upfront transparent pricing. While other companies attempt to build their businesses on the idea that the consumer is an idiot our business has been built over decades on the premise of always providing value-driven affordable solutions for entrepreneurs.",
  },
  {
    question: "Do I need to pay extra for compliance notifications?",
    answer:
      "Never, every single one of our formation packages includes lifetime compliance notifications via email and text alerts so you will always know when any federal or state compliance obligations are due. Upon being alerted you will always have the option to file the required documents yourself or have them filed by one of our incorporation specialists.",
  },
  {
    question: "Will I have the option to act as my own registered agent?",
    answer:
      "Yes, you can act as your own registered agent. However, we offer Registered Agent Service free of charge for the first year.",
  },
  {
    question: "Will I need to sign anything?",
    answer:
      "No, documents requiring signatures will be signed by our staff. We will sign as the incorporator for corporations, and for LLCs we will sign as the organizer.",
  },
];

export default function StartNewCompany() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const client = searchParams.get("client");
  const [waiting, setWaiting] = useState(false);
  const [entities, setEntities] = useState([]);
  const [states, setStates] = useState([]);
  const [info, setInfo] = useState({});
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [tableData, setTableData] = useState([]);
  const [addedServices, setAddedServices] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getEntityTypes();
    getState();
    getLocalInfo();
    getPackages();
  }, []);

  useEffect(() => {
    if (client) {
      getInfo();
    }
  }, [client]);

  useEffect(() => {
    if (entities.length > 0 && info?.entity_type) {
      const foundOption = entities.find(
        (opt) => opt.name === info?.entity_type
      );
      if (foundOption) {
        setSelectedEntity(foundOption);
      }
    }
  }, [info, entities]);

  useEffect(() => {
    if (entities.length > 0 && info?.state?.name) {
      const foundOption = states.find((opt) => opt.name === info?.state?.name);
      if (foundOption) {
        setSelectedState(foundOption);
      }
    }
  }, [info, states]);

  useEffect(() => {
    if (services?.length > 0) {
      generateTableData();
    }
  }, [services]);

  useEffect(() => {
    let amount =
      parseFloat(selectedPackage?.package_price) +
      parseFloat(selectedState?.state_fees);

    Object.values(addedServices).map((item, i) => {
      amount = amount + parseFloat(item?.service_price);
    });

    setTotal(amount);
  }, [selectedPackage, selectedState, addedServices]);

  const generateTableData = () => {
    let newData = [];

    services.map((item, i) => {
      newData.push(item);
    });
    setTableData(newData);
  };

  const getLocalInfo = () => {
    let uniqueId = localStorage.getItem("uniqueId");
    if (uniqueId) {
      navigate(`?client=${uniqueId}`);
      // getInfo(uniqueId);
    }
  };

  const getInfo = async () => {
    const response = await MakeApiCall(
      `api/v1/business/create/${client}/`,
      "get"
    );
    if (response.status) {
      setInfo(response);
    } else {
      localStorage.removeItem("uniqueId");
      navigate(`/start-new-company`);
      message.error(response.message);
    }
  };

  const getEntityTypes = async () => {
    const response = await MakeApiCall(`api/v1/dashboard/entity-types/`, "get");
    if (response.status) {
      setEntities(response.data);
      if (!client) {
        setSelectedEntity(response.data?.[0]);
      }
    } else {
      message.error("this api are not fetxh");
    }
  };

  const getState = async () => {
    const response = await MakeApiCall(
      `api/v1/dashboard/states/?limit=100`,
      "get"
    );
    if (response.status) {
      setStates(response.data);
      if (!client) {
        setSelectedState(response.data?.[0]);
      }
    } else {
      message.error("this api are not fetxh");
    }
  };

  const getPackages = async () => {
    const response = await MakeApiCall(
      `api/v1/dashboard/business-formation-packages/`,
      "get"
    );
    if (response.status) {
      setPackages(response.package_plan);
      setServices(response.included_service);
      setSelectedPackage(response.package_plan[1]);
    } else {
      message.error(response.message);
    }
  };

  const CustomDropdown = ({
    keyName,
    step,
    label,
    options,
    value,
    onChange,
    placeholder,
  }) => {
    return (
      <div style={styles.container}>
        <div style={styles.badge}>{step}</div>
        <div style={styles.content}>
          <div style={styles.label}>{label}</div>
          <Select
            value={value?.id}
            placeholder={placeholder}
            onChange={(value, option) => {
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.set(keyName, option.obj.name);
              navigate(`?${searchParams.toString()}`);
              onChange(option.obj);
            }}
            style={{ width: "100%" }}
            bordered={false}
            suffixIcon={<span style={{ color: "#000" }}>▼</span>}
          >
            <Option value={""} selected disabled>
              Select {label}
            </Option>
            {options.map((option) => (
              <Option
                key={option.id}
                value={option.id}
                obj={option}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  const getStart = async () => {
    setWaiting(true);
    const uniqueId = crypto.randomUUID();

    localStorage.setItem("uniqueId", uniqueId);

    const body = {
      client_ip: uniqueId,
      entity_type: selectedEntity?.id,
      state: selectedState?.id,
      business_formation_package: {
        package: selectedPackage?.id,
        included_services: Object.keys(addedServices),
      },
    };

    const response = await MakeApiCall(
      `api/v1/business/create/business-formation-package/`,
      "post",
      body
    );
    setWaiting(false);
    if (response.status) {
      navigate(`/setup-company?client=${uniqueId}`);
      // setEntities(response.data);
    } else {
      message.error(response.message);
    }
  };

  const rowElement = (item, packageId) => {
    let include = item?.packages?.find((elem) => elem.id === packageId);
    let serviceAdded =
      selectedPackage?.id === packageId ? addedServices[item?.id] : false;
    return (
      <div className="text-center">
        {include?.price_included ? (
          <span
            style={{
              color: selectedPackage?.id === include.id ? "#025497" : "grey",
            }}
          >
            ✔
          </span>
        ) : !include ? (
          "-"
        ) : (
          <Button
            type={serviceAdded ? "primary" : "default"}
            onClick={() => {
              if (selectedPackage?.id === packageId) {
                if (serviceAdded) {
                  setAddedServices((prev) => {
                    const updatedServices = { ...prev };
                    delete updatedServices[item.id]; // Remove item from object
                    return updatedServices;
                  });
                } else {
                  setAddedServices({ ...addedServices, [item.id]: item });
                }
              } else {
                setSelectedPackage(
                  packages?.find((elem) => elem.id === packageId)
                );
                setAddedServices({ [item.id]: item });
              }
            }}
          >
            + ${item.service_price}
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Business Formation Packages",
      dataIndex: "service_name",
      key: "service_name",
    },
    {
      title: (
        <>
          <div className="text-center mt-7">
            <Button className="selected-btn">{packages?.[0]?.name}</Button>
            <p className="mt-3 mb-0 fs-1">${packages?.[0]?.package_price}</p>
            <p className="mb-0 text-grey">
              +${selectedState?.state_fees} State fee
            </p>
            <div className="d-flex justify-content-center gap-2 align-items-center mt-3">
              <Icon icon="ic:outline-watch-later" width={18}></Icon>
              <span>
                {packages?.[0]?.process_time}{" "}
                {packages?.[0]?.duration_type_label ?? "Duration"}
              </span>
            </div>
          </div>
        </>
      ),
      dataIndex: "basic",
      key: "basic",
      className: selectedPackage?.id === 1 ? "selected-column" : "",
      onHeaderCell: () => ({
        onClick: () => {
          setAddedServices({});
          setSelectedPackage(packages[0]);
        },
      }),
      render: (_, obj) => rowElement(obj, 1),
    },
    {
      title: (
        <>
          {selectedPackage === 1 ? (
            <div className="recommended">Recommended</div>
          ) : null}
          <div className="text-center mt-7">
            <Button className="selected-btn">{packages?.[1]?.name}</Button>
            <p className="mt-3 mb-0 fs-1">${packages?.[1]?.package_price}</p>
            <p className="mb-0 text-grey">
              {" "}
              +${selectedState?.state_fees} State fee
            </p>
            <div className="d-flex justify-content-center gap-2 align-items-center mt-3">
              <Icon icon="ic:outline-watch-later" width={18}></Icon>
              <span>
                {packages?.[1]?.process_time}{" "}
                {packages?.[1]?.duration_type_label ?? "Duration"}
              </span>
            </div>
          </div>
        </>
      ),
      dataIndex: "standard",
      key: "standard",
      className: selectedPackage?.id === 2 ? "selected-column" : "",
      onHeaderCell: () => ({
        onClick: () => {
          setAddedServices({});
          setSelectedPackage(packages[1]);
        },
      }),
      render: (_, obj) => rowElement(obj, 2),
    },
    {
      title: (
        <>
          <div className="text-center mt-7">
            <Button className="selected-btn">{packages?.[2]?.name}</Button>
            <p className="mt-3 mb-0 fs-1">${packages?.[2]?.package_price}</p>
            <p className="mb-0 text-grey">
              +${selectedState?.state_fees} State fee
            </p>
            <div className="d-flex justify-content-center gap-2 align-items-center mt-3">
              <Icon icon="hugeicons:energy" width={18}></Icon>
              <span>
                {packages?.[2]?.process_time}{" "}
                {packages?.[2]?.duration_type_label ?? "Duration"}
              </span>
            </div>
          </div>
        </>
      ),
      dataIndex: "premium",
      key: "premium",
      className: selectedPackage?.id === 3 ? "selected-column" : "",
      onHeaderCell: () => ({
        onClick: () => {
          setAddedServices({});
          setSelectedPackage(packages[2]);
        },
      }),
      render: (_, obj) => rowElement(obj, 3),
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: "50px" }}>
        <Wrapper>
          <div style={{ marginTop: "30px" }}>
            <Row className="mb-10" gutter={[25, 25]}>
              <Col xs={24} sm={24} md={24} lg={24} className="">
                <Card
                  className="box-info "
                  style={{
                    overflow: "hidden",
                    textAlign: "center",
                    padding: "20px 40px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px 15px",
                      backgroundColor: "white",
                      border: "0.5px solid #E5E7EB",
                      borderRadius: "15px",
                      boxShadow: "0 1px 2px rgba(221, 221, 221, 0.3)",
                      width: "fit-content",
                      marginBottom: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Text>
                      <b>Excellent 4.7</b> out of 5{" "}
                    </Text>
                    <img
                      src={"./assets/images/img28.svg"}
                      style={{
                        height: "20px",
                      }}
                    />
                  </div>
                  <h1
                    level={1}
                    b
                    style={{
                      fontWeight: "900",
                      fontSize: "2.7rem",
                      textTransform: "uppercase",
                    }}
                  >
                    Start your business
                    <br />
                    with <span style={{ color: "#013B6A" }}>confidence</span>
                  </h1>
                  <Paragraph
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      marginTop: "20px",
                      opacity: 0.9,
                    }}
                  >
                    Join over 1,000,000 happy business owners. Get started by
                    choosing
                    <br />
                    your entity type and state of formation.
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      justifyContent: "center",
                      marginBottom: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <CustomDropdown
                      keyName="entityType"
                      step="1"
                      label="Entity Type"
                      value={selectedEntity}
                      options={entities}
                      onChange={(value) => setSelectedEntity(value)}
                    />
                    <CustomDropdown
                      keyName={"entityState"}
                      step="2"
                      label="State"
                      value={selectedState}
                      options={states}
                      onChange={(value) => {
                        setSelectedState(value);
                      }}
                    />
                  </div>
                  <span style={{ opacity: 0.9 }}>
                    State-specific pricing will display below once your
                    selection has been made.
                  </span>
                </Card>
              </Col>
            </Row>
            {selectedEntity && selectedState && (
              <div>
                <Row className="mb-10" gutter={[25, 25]}>
                  <Col xs={24} sm={24} md={12} lg={16}>
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      pagination={false}
                      bordered
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Card
                      style={{
                        border: "1px solid #eee",
                        borderRadius: "15px",
                      }}
                    >
                      <Title level={4}>Order Summary</Title>
                      <Flex
                        style={{
                          marginTop: "10px",
                          marginBottom: "0px",
                          opacity: 0.5,
                        }}
                      >
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {selectedPackage?.name} package:
                        </span>
                        <span style={{ marginLeft: "auto" }}>
                          ${selectedPackage?.package_price}
                        </span>
                      </Flex>
                      <Flex style={{ opacity: 0.5 }}>
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {selectedState?.name} state fee:
                        </span>
                        <span style={{ marginLeft: "auto" }}>
                          ${selectedState?.state_fees}
                        </span>
                      </Flex>
                      {Object.values(addedServices).map((item, i) => {
                        return (
                          <Flex style={{ opacity: 0.5 }} key={i}>
                            <span
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.service_name}:
                            </span>
                            <span style={{ marginLeft: "auto" }}>
                              ${item?.service_price}
                            </span>
                          </Flex>
                        );
                      })}
                      <div
                        style={{
                          height: "1px",
                          backgroundColor: "#eee",
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      />
                      <Flex>
                        <span
                          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                        >
                          Total:
                        </span>
                        <span
                          style={{
                            marginLeft: "auto",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          ${total}
                        </span>
                      </Flex>
                      {/* <Link
                        to={`/setup-company?entityType=${selectedEntity?.name}&entityState=${selectedState?.name}`}
                      > */}
                      <Button
                        type="primary"
                        block={true}
                        style={{
                          marginTop: "10px",
                        }}
                        size="large"
                        onClick={getStart}
                        disabled={waiting}
                      >
                        {waiting ? "Please wait..." : "Get Started"}
                      </Button>
                      {/* </Link> */}
                      <div style={{ textAlign: "center", marginTop: "13px" }}>
                        <b>
                          <Icon
                            icon={"grommet-icons:announce"}
                            width={15}
                          ></Icon>{" "}
                          One-time fee
                        </b>
                      </div>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#999",
                          textAlign: "center",
                          paddingBottom: "0px",
                          marginTop: "5px",
                        }}
                      >
                        Unlike companies that charge annual fees, our formation
                        fee is one-time.
                      </p>
                    </Card>
                  </Col>
                </Row>

                <div>
                  <Title>Frequently Asked Questions</Title>
                  <div style={{ marginTop: "30px" }}>
                    <Collapse
                      className="cus-accordination"
                      expandIcon={({ isActive }) => (
                        <Icon
                          icon="solar:round-alt-arrow-down-line-duotone"
                          width={30}
                          className=""
                          style={{
                            transform: `rotate(${
                              isActive ? "180deg" : "0deg"
                            })`,
                            color: "#013B6A",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        ></Icon>
                      )}
                      accordion
                    >
                      {faqs.map((item, index) => (
                        <>
                          <Panel
                            header={
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "500",
                                  color: "#1c1b18",
                                }}
                              >
                                {item.question}
                              </span>
                            }
                            key={index}
                          >
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "21px",
                                fontWeight: "500",
                                color: "#434343",
                              }}
                            >
                              {item.answer}
                            </p>
                          </Panel>
                          {index !== faqs.length - 1 && (
                            <div className="seprator my-3"></div>
                          )}
                        </>
                      ))}
                    </Collapse>
                  </div>
                </div>

                <div
                  style={{
                    margin: "50px 0px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  Didn’t find the answer here? Don’t hesitate to{" "}
                  <a style={{ color: "#013B6A", fontWeight: "500" }}>
                    Contact Us
                  </a>{" "}
                  and we’ll be more than happy to help you!
                </div>

                <Row className="mb-10" gutter={[25, 25]}>
                  <Col xs={24} sm={24} md={24} lg={24} className="">
                    <Card
                      className="box-info "
                      style={{ overflow: "hidden", backgroundColor: "#1C1B18" }}
                    >
                      <Title style={{ textAlign: "center", color: "#fff" }}>
                        Bizee simplifies every step,
                        <br /> so you can focus on what you do best
                      </Title>
                      <Row style={{ marginTop: "20px" }} gutter={[25, 25]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <span
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                          >
                            It's easy! Bizee is your expert guide through each
                            step.{" "}
                          </span>
                          <div
                            style={{
                              marginTop: "30px",
                            }}
                          />
                          <span
                            style={{
                              fontSize: "15px",
                              color: "#fff",
                              fontWeight: "400",
                            }}
                          >
                            Filing a new business entity can be straight forward
                            with the right help. Bizee assists with the filing
                            process, making it quick and ensuring it’s done
                            right—the first time.
                          </span>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                              marginTop: "20px",
                            }}
                          >
                            {[
                              "Streamlined Setup",
                              "Resource-Rich",
                              "Cost-Effective",
                              "Ongoing Compliance",
                              "Comprehensive Support",
                            ].map((item, i) => {
                              return (
                                <Flex key={i} align="center">
                                  <Icon
                                    icon={"lets-icons:check-fill"}
                                    color="#013B6A"
                                    width={30}
                                  ></Icon>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "#fff",
                                      fontWeight: "400",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {item}
                                  </span>
                                </Flex>
                              );
                            })}
                          </div>
                        </Col>

                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          className="main-img d-flex align-items-center justify-content-end"
                        >
                          <img src={"./assets/images/img29.png"} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Wrapper>
      </Content>
    </Layout>
  );
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    width: 220,
    border: "2px solid #013B6A",
    borderRadius: 30,
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  badge: {
    backgroundColor: "#013B6A",
    color: "#fff",
    fontWeight: "bold",
    padding: "12px 14px",
    fontSize: 14,
    // borderRadius: "30px 0 0 30px",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  content: {
    flex: 1,
    padding: "8px 12px",
  },
  label: {
    fontSize: 14,
    fontWeight: "500",
    color: "#666",
  },
};
