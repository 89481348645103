export const AdminMenu = [
  { 
    key: 'dashboard', 
    label: 'Dashboard', 
    icon:'solar:layers-minimalistic-bold-duotone' 
  },
  { 
    key: 'manage-user', 
    label: 'Manage User', 
    icon:'solar:users-group-rounded-line-duotone' 
  },
//   { key: 'manage-user', label: 'Users', children: [{ key: 'manage-user', label: 'Add User' }] },
];