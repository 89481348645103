import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Collapse, Flex, Row, Typography } from "antd";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

export default function businessInsurance() {
  const data = [
    {
      title: "General Liability",
      description:
        "Provides financial protection from everyday workplace risks such as property damage, customer injury and lawsuits.",
      covered: [
        "Bodily injury",
        "Property damage",
        "Personal and Advertising Injury",
      ],
    },
    {
      title: "Business Owners Policy",
      description:
        "BOP insurance is a packaged insurance coverage which includes general liability, commercial property and business interruption insurance.",
      covered: [
        "General Liability",
        "Commercial Property",
        "Business Interruption",
      ],
    },
    {
      title: "Workers Compensation",
      description:
        "Required by law in most states, workers comp. insurance covers costs of medical care and lost wages to employees that suffer any injury or illness as a direct result of employment.",
      covered: ["Medical expenses", "Employee Liability", "Survivor Benefits"],
    },
    {
      title: "Cyber Insurance",
      description:
        "Covers a broad range of risks including data loss, breaches and software attacks. Cyber insurance can also cover tech-related threats and loss of data which results in lost, stolen or tampered-with information.",
      covered: ["Data breach", "System Hacks", "Ransomware"],
    },
    {
      title: "Professional Liability / Errors & Omissions",
      description:
        "Covers your business from customer legal disputes arising from professional negligence, mistakes, or missed client deadlines.",
      covered: ["Negligence", "Unfulfilled Task", "Mistakes / legal costs"],
    },
    {
      title: "Management Liability",
      description:
        "Protects founders, executives, company management, and board members against claims related to management decisions.",
      covered: [
        "Directors & Officers",
        "Employment Practices Liability",
        "Fiduciary and Crime",
      ],
    },
  ];

  const faqs = [
    {
      question: "What Is Coverdash?",
      answer:
        "Coverdash is the world's fastest business insurance agency focused on small businesses, e-commerce merchants, freelancers, and all other growing businesses. Bizee partnered with Coverdash to allow users to compare quotes and purchase policies from more than 30 of the nation's largest insurers instantly. Find tailored coverage at industry leading prices on your Bizee dashboard in seconds.",
    },
    {
      question: "How can I get my business insured through Bizee?",
      answer:
        "Through our partnership with Coverdash, you can now purchase business insurance policies directly on your Bizee dashboard! Get started by choosing the right coverage, provide your business info, and you will be matched with the insurance you need in seconds.",
    },
    {
      question:
        "How do I generate a Certificate of Insurance (COI) or handle other insurance related questions?",
      answer:
        "Through Coverdash's customer dashboard, you'll have the ability to easily create / download custom certificates of insurance (COIs) all on your own. Additionally, if you need more help or want an agent to create a COI for you, you can submit a request on your dashboard and an agent will respond in 24-48 hrs.",
    },
    {
      question:
        "What kinds of businesses does Coverdash cover and what types of insurance do you offer?",
      answer:
        "Coverdash provides insurance coverage for startups, small business, freelancers, e-commerce merchants, and a broad range of other enterprises. They offer a variety of insurance options, including General Liability, Business Owners Policy, Workers Comp., Cyber, Professional Liability, Management Liability (including Directors & Officers, Employment Practices Liability, and Fiduciary and Crime), commercial auto, and more.",
    },
    {
      question:
        "I have policies with other insurance brokers/carriers. Why should I switch?",
      answer:
        "To find out how much you can save on your existing coverage, get quotes and compare rates at any time by filling out an application. If you elect to use Coverdash as your broker, you can share certificates, file claims and schedule renewals with just a click of a button, and get access to immediate support from licensed professionals. Coverdash's platform makes business insurance as easy as possible so that you can get back to what’s really important…your business.",
    },
  ];

  return (
    <Wrapper>

    <div>
      <Row className="mb-10" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info ">
            <Row gutter={[25, 25]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 16px",
                    backgroundColor: "white",
                    border: "0.5px solid #E5E7EB",
                    borderRadius: "15px",
                    boxShadow: "0 1px 2px rgba(221, 221, 221, 0.3)",
                    width: "fit-content",
                    marginBottom: "20px",
                  }}
                >
                  <img src={"./assets/images/img12.svg"} />
                  <img
                    src={"./assets/images/img14.svg"}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  />
                  <img src={"./assets/images/img13.svg"} />
                </div>
                <Title className="mb-0" level={2}>
                  Get Business Insurance in Seconds With Coverdash
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Coverdash lets business owners compare quotes and purchase
                  policies from more than 30 of the largest insurers in the
                  country.
                </Paragraph>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                className="d-flex justify-content-end align-items-cneter main-img"
                >
                <img src={"./assets/images/img11.png"} className="w-100" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div>
        <Title level={3} style={{ marginBottom: "5px" }}>
          What Types of Insurance Does Coverdash Offer?
        </Title>
        <Paragraph
          style={{ fontSize: "15px", fontWeight: "lighter", opacity: 0.9 }}
          >
          Through relationships with 30+ insurance providers, Coverdash is able
          to secure protection for businesses of all sizes and across all
          industries.
        </Paragraph>
        <Row  gutter={[20,20]}>
          {data.map((item, index) => {
            return (
              <Col xs={24} sm={24} md={12} lg={12} key={index}>
                <Card
                  style={{
                    borderRadius: 10,
                    boxShadow: "0 4px 10px rgba(221,221,221,0.5)",
                    border: "1px solid #f0f0f0",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <Title className="d-flex align-items-center " level={3}>
                      <Icon
                        className="me-2"
                        icon="uit:shield-check"
                        width={30}
                      />{" "}
                      {item.title}
                    </Title>
                    <Text
                      style={{ color: "#666", fontSize: "15px", opacity: 0.7 }}
                    >
                      {item.description}
                    </Text>

                    <div style={{ marginTop: "30px" }}>
                      <Title level={5} style={{ marginBottom: 10 }}>
                        What’s covered:
                      </Title>
                      {item.covered?.map((cover, i) => {
                        return (
                          <Row key={i}>
                            <Col sm={2}>
                              <Icon
                                className="me-3"
                                icon="ic:round-check"
                                width={22}
                              />
                            </Col>
                            <Col sm={20}>{cover}</Col>
                          </Row>
                        );
                      })}
                    </div>

                    <Button
                      type="primary"
                      block
                      style={{
                        marginTop: 15,
                      }}
                      size="large"
                      >
                      GET A QUOTE
                    </Button>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <Row className="mb-10" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Title level={3} style={{ textAlign: "center" }}>
              Frequently Asked Questions
            </Title>
            <Paragraph style={{ textAlign: "center" }}>
              Everything you need to know about the product and billing.
            </Paragraph>
            <div style={{ marginTop: "30px" }}>
              <Collapse
                className="cus-accordination"
                expandIcon={({ isActive }) => (
                  <Icon
                    icon="solar:round-alt-arrow-down-line-duotone"
                    width={25}
                    className=""
                    style={{
                      transform: `rotate(${isActive ? "180deg" : "0deg"})`,
                    }}
                    ></Icon>
                )}
                accordion
              >
                {faqs.map((item, index) => (
                  <>
                    <Panel header={item.question} key={index}>
                      <p className="mb-0">{item.answer}</p>
                    </Panel>
                    {index !== faqs.length - 1 && (
                      <div className="seprator my-3"></div>
                    )}
                  </>
                ))}
              </Collapse>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
              <Col xs={12} sm={10} md={3} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  >
                  <Icon icon="uit:shield-check" color="primary" width={50} />
                </div>
              </Col>
               <Col xs={12} sm={14} md={17} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    Trademark
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    Take steps to protect your brand and valuable company assets
                    such as your name, logo, and tagline.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
              <Col xs={12} sm={10} md={3} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Icon icon="proicons:thumbs-up" color="primary" width={50} />
                </div>
              </Col>
               <Col xs={12} sm={14} md={17} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    Licenses & Permits
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    Determine all business licenses and permits required at the
                    federal, state, county, and municipal level.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
              <Col xs={12} sm={10} md={3} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Icon
                    icon="fluent:send-48-regular"
                    color="primary"
                    width={50}
                  />
                </div>
              </Col>
               <Col xs={12} sm={14} md={17} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    Business Contracts
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    Get access to 25+ high quality legal documents drafted by a
                    business attorney to assist with running your business.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
                    </Wrapper>
  );
}
