import SignUp from "../module/auth/sign-up";
import SignIn from "../module/auth/sign-in";
import ForgotPassword from "../module/auth/forgot-password";
import ResetPassword from "../module/auth/reset-password";
import { SetupCompany, StartNewCompany } from "../module/user";

export const Auth = [
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "start-new-company",
    element: <StartNewCompany />,
  },
  {
    path: "setup-company",
    element: <SetupCompany />,
  },
];
