import styled from "styled-components";

export const WrapperInner = styled.div`
.ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgb(0 0 0) !important;
    background: rgb(255 255 255 / 25%);
    }
    .seprator{
        border-bottom: 1px dashed #d9d9d9;
    }
    .ant-list-split .ant-list-item {border-block-end: 1px dashed rgb(5 5 5 / 12%);}
    .rdi-btn {
        border: 1px solid #e5e5e5;
        padding: 10px 20px;
        font-weight: 600;
        border-radius: 10px;
        margin-right: 10px;
        background-color: #f7f7f7;
    }
`;