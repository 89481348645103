import React from 'react'
import { Wrapper } from './style'
import { Avatar, Button, Card, Col, Collapse, Flex, List, Row, Space, Tag, Typography } from 'antd';
import { Icon } from '@iconify/react/dist/iconify.js';
import { primaryColor } from '../../../config';
import { BellOutlined, FileTextOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { Meta } = Card;

function Compliance() {
  return (
    <Wrapper>
      <Title level={4}>Upcoming Compliance Events</Title>
      <Row className="mb-5" gutter={[25, 25]}>
        <Col xs={24} sm={12} md={8} lg={6} >
          <Card hoverable >
            <img className='mb-4' src="../assets/images/federal-requirement-card.png" style={{ width: "100%" }} alt="" />
            <Tag color="volcano">Federal Requirement</Tag>
            <Title className='mt-3 mb-4' level={4}>BOI Report</Title>
            <div className='seprator mb-4'></div>
            <Flex className='mb-3' wrap align='center' justify='space-between'>
              <Text>Due Date:</Text>
              <Text style={{ fontSize: '15px' }}><strong>Dec. 31 2024</strong></Text>
            </Flex>
            <Flex wrap align='center' justify='space-between'>
              <Text>Total Fee:</Text>
              <Text style={{ fontSize: '15px' }}><strong>$99</strong></Text>
            </Flex>

            <Button size='large' type="primary" block style={{ marginTop: "10px" }}>Start Filing</Button>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} >
          <Card hoverable >
            <img className='mb-4' src="../assets/images/state-requirement-card.png" style={{ width: "100%" }} alt="" />
            <Tag color="volcano">State Requirement</Tag>
            <Title className='mt-3 mb-4' level={4}>Annual Report (IL)</Title>
            <div className='seprator mb-4'></div>
            <Flex className='mb-3' wrap align='center' justify='space-between'>
              <Text>Due Date:</Text>
              <Text style={{ fontSize: '15px' }}><strong>May. 31 2025</strong></Text>
            </Flex>
            <Flex wrap align='center' justify='space-between'>
              <Text>Total Fee:</Text>
              <Text style={{ fontSize: '15px' }}><strong>$176</strong></Text>
            </Flex>

            <Button size='large' type="primary" block style={{ marginTop: "10px" }}>Start Filing</Button>
          </Card>
        </Col>
      </Row>
      <Row className="mb-5" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} >
          <Card className="box-info ">
            
            <Row gutter={[18, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title className="d-flex align-items-center " style={{ color: '#025497' }} level={5}><Icon className="me-2" icon='solar:chat-line-line-duotone' width={22}></Icon> Recommendations</Title>
                <Title className="mb-5" level={3}>EZLaw State Filings</Title>

                <Paragraph style={{ opacity: '.7' }}>
                  Easily manage your state filings with one subscription. Stay compliant while saving time and money.
                </Paragraph>
                <ul>
                  <li><Icon color={primaryColor} icon='solar:check-circle-broken' width={20} className='me-2'></Icon>Digital filing service and storage</li>
                  <li><Icon color={primaryColor} icon='solar:check-circle-broken' width={20} className='me-2'></Icon>24/7 compliance monitoring and alerts</li>
                  <li><Icon color={primaryColor} icon='solar:check-circle-broken' width={20} className='me-2'></Icon>Convenient, efficient and stress free filings</li>
                </ul>
                <Flex gap={10} wrap>
                  <Button className='px-5' size='large' color="primary" variant="solid">Subscribe</Button>
                  <Button className='px-5' size='large' color="primary" variant="outlined">Learn more</Button>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className='d-flex justify-content-end align-items-center complianc-img'>
              <img  src='../assets/images/23673006_6770951.svg' alt="" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mb-5" gutter={[25, 25]}>
        <Col span={24}>
          <Card className='info-card'>
            <Flex align="center" justify="space-between" wrap gap={20}>
              <Meta
                className=' d-flex align-items-center'
                avatar={
                  <Avatar
                    style={{ backgroundColor: "rgb(218 230 241)" }}
                    size={55}
                    icon={
                      <Icon
                        icon="solar:history-line-duotone"
                        style={{ color: primaryColor }}
                        width={27}
                      ></Icon>
                    }
                  />
                }
                title="Filing history"
                description={
                  <span className=' d-flex' >
                    To see the documents you have already filed, visit your Order History.
                  </span>
                }
              />
              <>
                <Button className='px-5' size='large' color="primary" variant="solid">Go to order history</Button>
              </>
            </Flex>
          </Card>
        </Col>
        <Col span={24}>


          <Collapse style={{ border: 0, borderRadius: '10px', marginTop: '5px' }} expandIconPosition="end">
            <Panel style={{ border: 0, background: '#fff', borderRadius: '10px', }} header={<>
              <Flex style={{ border: '0', boxShadow: 'none' }} className='ant-card' align="center" justify="space-between">
                <Meta
                  className=' d-flex align-items-center'
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "rgb(218 230 241)" }}
                      size={55}
                      icon={
                        <Icon
                          icon="solar:history-line-duotone"
                          style={{ color: primaryColor }}
                          width={27}
                        ></Icon>
                      }
                    />
                  }
                  title="Illinois: Annual Report"
                  description={
                    <span className=' d-flex' >
                      <b>Frequency:</b> Annually
                    </span>
                  }
                />
              </Flex>
            </>} key="1">
              <p><strong>Due Date:</strong> By the end of the month preceding the registration anniversary month</p>
              <p><strong>Filing Fee:</strong> $77</p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Compliance