import styled from "styled-components";

export const Wrapper = styled.div`
.ant-list-item-meta-title{margin-bottom:0;margin-left:10px}
.avt-cus{
    background-color: #025497;
    font-size:14px !important;
    font-weight: 600 !important;
    line-height: 17px !important;
    text-transform: uppercase;
}
`;