import AllOrders from "../module/user/allOrders";
import Dashboard from "../module/user/dashboard";
import Recommendations from "../module/user/recommendations";
import Settings from "../module/user/settings";
import CompletedDocuments from "../module/user/completedDocuments";
import VirtualMailBoxOverview from "../module/user/virtualMailbox/overview";
import VirtualMailBox from "../module/user/virtualMailbox";
import BusinessContractTemplates from "../module/user/businessContractTemplates";
import { element } from "prop-types";
import CompanyInfo from "../module/user/companyInfo";
import RegisteredAgent from "../module/user/registeredAgent";
import OrderRegisterAgent from "../module/user/registeredAgent/orderRegisterAgent";
import ChangeRegisterAgent from "../module/user/registeredAgent/changeRegisterAgent";
import Compliance from "../module/user/compliance";
import StateAndIRSFilings from "../module/user/state&IRS";
import {
  BusinessFormationKit,
  BusinessInsurance,
  BusinessListings,
  DomainEmail,
  Financial,
  FreelanceServices,
  GoogleMyBusiness,
  Legal,
  Payments,
  SetupCompany,
  StartNewCompany,
  Taxes,
  Website,
} from "../module/user";
// import MyProfile from "../module/user/settings/myProfile";
// import PaymentMethod from "../module/user/settings/paymentMethod";

export const User = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "recommendations",
    element: <Recommendations />,
  },
  {
    path: "settings",
    element: <Settings />,
  },
  {
    path: "virtual-mailbox/overview",
    element: <VirtualMailBoxOverview />,
  },
  {
    path: "virtual-mailbox",
    element: <VirtualMailBox />,
  },
  {
    path: "all-orders",
    element: <AllOrders />,
  },
  {
    path: "completed-documents",
    element: <CompletedDocuments />,
  },
  {
    path: "business-contract-templates",
    element: <BusinessContractTemplates />,
  },
  {
    path: "company-info",
    element: <CompanyInfo />,
  },
  {
    path: "registered-agent",
    element: <RegisteredAgent />,
  },

  {
    path: "order-register-agent",
    element: <OrderRegisterAgent />,
  },

  {
    path: "change-register-agent",
    element: <ChangeRegisterAgent />,
  },
  {
    path: "compliance",
    element: <Compliance />,
  },
  {
    path: "state-irs",
    element: <StateAndIRSFilings />,
  },
  {
    path: "domain-email",
    element: <DomainEmail />,
  },
  {
    path: "website",
    element: <Website />,
  },
  {
    path: "logo",
    element: <></>,
  },
  {
    path: "taxes",
    element: <Taxes />,
  },
  {
    path: "legal",
    element: <Legal />,
  },
  {
    path: "business-insurance",
    element: <BusinessInsurance />,
  },
  {
    path: "financial",
    element: <Financial />,
  },
  {
    path: "google-my-business",
    element: <GoogleMyBusiness />,
  },
  {
    path: "business-formation-kit",
    element: <BusinessFormationKit />,
  },
  {
    path: "business-listing",
    element: <BusinessListings />,
  },
  {
    path: "freelance-services",
    element: <FreelanceServices />,
  },
  // {
  //   path: "start-new-company",
  //   element: <StartNewCompany />,
  // },
  // {
  //   path: "setup-company",
  //   element: <SetupCompany />,
  // },
  {
    path: "payments",
    element: <Payments />,
  },
  // {
  //   path: "reports/inventory-ledger-report",
  //   element: <InventoryLedgerReport />,
  // },
];
