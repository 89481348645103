import styled from "styled-components";

export const Wrapper = styled.div`
    .seprator{border-bottom: 1px dashed #d9d9d9;}
    .ant-divider {border-block-start: 1px dashed rgb(0 0 0 / 13%);}
    .ant-list-split .ant-list-item {border-block-end: 1px dashed rgba(5, 5, 5, 0.15);    
    }

    @media (min-width: 320px) and (max-width:576px){
        .comany-info{
            width:100px;
        }
    }
   
    @media (min-width: 768px) and (max-width:991px){
        .comany-info{
            width:131px;
        }
    }
    @media (min-width: 992px) and (max-width:1199px){
        .comany-info{
            width:131px;
        }
    }
    @media (min-width: 1200px) and (max-width:1300px){
        .comany-info{
            width:248px;
        }
    }
`;
