import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Flex, Input, Row, Typography } from "antd";
import React from "react";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

export default function financial() {
  const cards = [
    {
      icon: "lets-icons:money-light",
      title: "Business Banking",
      description:
        "Opening a business bank account for your new endeavor doesn't just serve to legitimize your business it helps to protect your personal assets.",
    },
    {
      icon: "mynaui:credit-card-check-solid",
      title: "Credit Card",
      description:
        "The availability of credit can be critical for small businesses to fund operations, invest in new opportunities, or provide needed cash flow.",
    },
    {
      icon: "solar:wallet-outline",
      title: "Business Lending",
      description:
        "Elevate business growth with easy lending. Apply online for swift approvals, flexible terms, and financial support that scales with your success.",
    },
    {
      icon: "solar:dollar-line-duotone",
      title: "Payment Processing",
      description:
        "Seamless payment processing and POS systems that simplify invoicing, accelerate transactions, and provide customers with a smoother and faster checkout experience.",
    },
    {
      icon: "fluent:calculator-multiple-24-regular",
      title: "Tax and Financial Management",
      description:
        "Effective tax and financial planning is crucial for small business success, safeguarding profits and compliance. Utilize our 30-minute consultation to clarify your strategy and questions.",
    },
  ];

  return (
    <Wrapper>

    <div>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} >
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                >
                  <Icon
                    className="me-2"
                    icon="solar:dollar-line-duotone"
                    width={22}
                    />{" "}
                  Financial
                </Title>
                <Title className="mb-0" level={2}>
                  Save yourself the time and headache on daily financial tasks
                  with the right financial solutions.
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  We want to help your business thrive - not just today, but
                  well into tomorrow. We worked with small business owners like
                  you to develop solutions that will cater to your growing
                  business needs.
                </Paragraph>
              </Col>
              
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items-cneter main-img">
                <img
                  src={"./assets/images/img15.png"}
                  className="w-100"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="cus-card-box" gutter={[25, 25]}>
        {cards?.map((item, i) => {
          return (
            <Col
            xs={24}
              sm={12}
              md={12}
              lg={8}
              className=""
              key={i}
              style={{
                marginBottom: "20px",
              }}
            >
              <Card
                hoverable
                actions={[
                  <Button size="large" color="primary" variant="text">
                    Learn more{" "}
                    <Icon
                      icon="solar:arrow-right-line-duotone"
                      width={20}
                    ></Icon>
                  </Button>,
                ]}
              >
                <div className="icon">
                  <Icon icon={item.icon} width={28}></Icon>
                </div>
                <h3>{item.title}</h3>
                <p className="mb-0">
                  <Meta description={item.description} />
                </p>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row style={{ marginTop: "20px" }} gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
             <Col xs={12} sm={10} md={6} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    //   padding: "26px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    //   textAlign: "center",
                    display: "flex",
                  }}
                  >
                  <Icon icon="mdi-light:file" color="primary" width={50} />
                </div>
              </Col>
             <Col xs={12} sm={14} md={14} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    State & IRS
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    As your business grows we'll help make sure that you have
                    the resources at hand to service your companies ongoing
                    needs.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }} gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 22]}>
             <Col xs={12} sm={10} md={6} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    //   padding: "26px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    //   textAlign: "center",
                    display: "flex",
                  }}
                  >
                  <Icon icon="uit:shield-check" color="primary" width={50} />
                </div>
              </Col>
             <Col xs={12} sm={14} md={14} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    Legal
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    As a business owner, you are responsible for making sure
                    your company has the proper protection against outside
                    threats while maintaining compliance to operate legally on a
                    federal, state, city, and county levels.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
                  </Wrapper>
  );
}
