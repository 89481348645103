import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Flex, Input, Row, Typography } from "antd";
import React from "react";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

export default function website() {
  return (
    <Wrapper>

    <div>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row guutter={[16, 20]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                  >
                  <Icon className="me-2" icon="line-md:computer" width={22} />{" "}
                  Website
                </Title>
                <Title className="mb-0" level={2}>
                  Creating a website for your business can be easier than you
                  think.
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Building a website for your business doesn't have to be a
                  headache and it certainly doesn't require hiring an expensive
                  agency to create it for you.
                </Paragraph>

                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  These options can help you design a professional site for your
                  business. Best yet, you can try it for free!
                </Paragraph>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                className="d-flex justify-content-center align-items-center main-img"
              >
                <img
                  src={"./assets/images/img2.png"}
                 
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="cus-card-box" gutter={[25, 25]}>
        <Col xs={24} sm={12} md={12} lg={12} className="">
          <Card
            hoverable
            actions={[
              <Button size="large" color="primary" variant="text">
                Learn more{" "}
                <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
              </Button>,
            ]}
          >
            <div className="icon">
              <Icon icon="oui:controls-vertical" width={28}></Icon>
            </div>
            <h3>Do-It-Yourself</h3>
            <p className="mb-0">
              {" "}
              <Meta description="Use a website builder solution to upload information, drag and drop images, and customize the text all on your own." />
            </p>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} className="">
          <Card
            hoverable
            actions={[
              <Button size="large" color="primary" variant="text">
                {" "}
                Learn more{" "}
                <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
              </Button>,
            ]}
          >
            <div className="icon">
              <Icon icon="mdi:cart-outline" width={28}></Icon>
            </div>
            <h3>E-commerce</h3>
            <p className="mb-0">
              <Meta description="Give me an all-in-one solution to manage inventory, payments, and provide a shopping cart to sell my products with ease." />
            </p>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }} gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} >
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
              <Col xs={12} sm={10} md={6} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Icon
                    icon="icon-park-outline:protect"
                    color="primary"
                    width={50}
                    />
                </div>
              </Col>
              <Col xs={12} sm={14} md={14} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    State & IRS
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    As your business grows we'll help make sure that you have
                    the resources at hand to service your companies ongoing
                    needs.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }} gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
            <Col xs={12} sm={10} md={6} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    //   padding: "26px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    //   textAlign: "center",
                    display: "flex",
                  }}
                  >
                  <Icon icon="mage:dollar" color="primary" width={50} />
                </div>
              </Col>
              <Col xs={12} sm={14} md={14} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    Financial
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    Having the right financial strategy gives you the peace of
                    mind to focus on what matters - running your business.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }} gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[20, 20]}>
              <Col xs={12} sm={14} md={6} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    //   padding: "26px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    //   textAlign: "center",
                    display: "flex",
                  }}
                  >
                  <Icon icon="mage:home-check" color="primary" width={50} />
                </div>
              </Col>
              <Col xs={12} sm={14} md={14} lg={17}>
                <div>
                  <Title level={4} style={{ marginBottom: "2px" }}>
                    Legal
                  </Title>
                  <Paragraph style={{ opacity: 0.6 }}>
                    As a business owner, you are responsible for making sure
                    your company has the proper protection against outside
                    threats while maintaining compliance to operate legally on a
                    federal, state, city, and county levels.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" className="w-100 btn-learn">
                  Learn More
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
                  </Wrapper>
  );
}
