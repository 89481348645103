import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Flex, Input, Row, Typography } from "antd";
import React from "react";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

export default function businessListings() {
  const cards = [
    {
      icon: "simple-line-icons:check",
      title: "Manage Listings From One Place",
      description:
        "Keeping your business information up-to-date isn't something you can do once and forget about. It requires constant maintenance. Update your brand locations from a single source of truth.",
    },
    {
      icon: "simple-line-icons:check",
      title: "The Direct Line to Google in Real Time",
      description:
        "Manage your data on Google My Business, including Google Maps. Thanks to our close partnership with Google and world-class integration, you can process all of your GMB details in real time.",
    },
    {
      icon: "simple-line-icons:check",
      title: "Secure your Business Information",
      description:
        "Many directory apps scrape your info from the web, which isn't always accurate. To ensure that your information is not altered by them, we immediately lock your listing, so you are in control of the information that is displayed to your customers.",
    },
  ];

  return (
    <Wrapper>

    <div>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} >
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row gutter={[25, 25]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                >
                  <Icon className="me-2" icon="solar:map-linear" width={22} />{" "}
                  Business Listings
                </Title>
                <Title className="mb-0" level={2}>
                  Make it easy for customers to contact your business
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Control your businesses online listings and build trust with
                  your customers from the first click.
                </Paragraph>
              </Col>
              
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items-center main-img">
                <img
                  src={"./assets/images/img23.png"}
                  style={{
                    height: "250px",
                  }}
                  />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row gutter={[25, 25]}>
              <Col
                xs={24} sm={24} md={12} lg={12}
                style={{ paddingLeft: "30px", paddingTop: "30px" }}
                >
                <Paragraph
                  style={{
                    opacity: ".6",
                    fontSize: "15px",
                  }}
                  >
                  Business Listing Scan
                </Paragraph>
                <Title level={2} className="mt-0">
                  Scanning your business
                </Title>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px 20px",
                    backgroundColor: "white",
                    border: "0.5px solid #E5E7EB",
                    borderRadius: "15px",
                    boxShadow: "0 1px 2px rgba(221, 221, 221, 0.3)",
                    width: "fit-content",
                    marginBottom: "20px",
                  }}
                  >
                  <img
                    src={"./assets/images/img24.svg"}
                    style={{
                      height: "100px",
                      marginRight: "10px",
                    }}
                    />
                  <div>
                    <Title level={4} style={{ fontWeight: "bold" }}>
                      Conversion rate
                    </Title>
                    <Paragraph style={{ opacity: ".6", fontSize: "15px" }}>
                      Only 30% of the time customers search for you, they will
                      see the correct information.
                    </Paragraph>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}  className="d-flex justify-content-center align-items-center">
                <img
                  src={"./assets/images/img25.png"}
                  style={{
                    // height: "250px",
                    width: "90%",
                    marginLeft: "auto",
                  }}
                  />
                <div
                  style={{
                    marginTop: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "25px 20px",
                    backgroundColor: "white",
                    border: "0.5px solid #E5E7EB",
                    borderRadius: "15px",
                    boxShadow: "2px 1px 2px rgba(221, 221, 221, 0.5)",
                    width: "fit-content",
                    marginBottom: "20px",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 100,
                  }}
                  >
                  <Title level={5}>IYSHIPPING LLC</Title>
                  <Paragraph
                    style={{
                      opacity: ".6",
                      fontSize: "15px",
                      marginTop: "10px",
                      marginBottom: "0px",
                    }}
                    >
                    13120 Georgetown Drive, Palos Park, Illinois 60462
                  </Paragraph>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mb-7">
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Row align={"middle"} gutter={[25, 25]}>
             <Col xs={12} sm={14} md={6} lg={3}>
                <div
                  style={{
                    backgroundColor: "#ADC0D5",
                    height: "95px",
                    width: "95px",
                    //   padding: "26px",
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    //   textAlign: "center",
                    display: "flex",
                  }}
                  >
                  <Icon
                    icon="garden:growth-chart-stroke-12"
                    color="primary"
                    width={50}
                    />
                </div>
              </Col>
              <Col xs={12} sm={14} md={14} lg={17}>
                <div>
                  <Title level={4} style={{ fontWeight: "bolder" }}>
                    Take back control of your listing and improve visibility to
                    potential customers.
                  </Title>
                  <Paragraph style={{ opacity: 0.7, fontSize: "15px" }}>
                    Accurate listings can improve conversion rate up to{" "}
                    <span style={{ color: "#015497" }}>80%</span>
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} >
                <Button size="large" type="primary" className="w-100">
                  CLAIM YOUR LISTING
                  <Icon icon="solar:arrow-right-line-duotone" width={20}></Icon>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} >
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row>
              <Col
                xs={24} sm={24} md={12} lg={12}
                style={{ paddingLeft: "30px", paddingTop: "30px" }}
              >
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px 20px",
                    borderRadius: "15px",
                    width: "fit-content",
                    marginBottom: "20px",
                    backgroundColor: "#FAFBFC",
                  }}
                >
                  <Paragraph
                    style={{
                      fontSize: "23px",
                      fontWeight: "bold",
                      color: "#2A2A2A",
                      marginBottom: "0px",
                    }}
                    >
                    Bizee Listings puts you in control of the facts about your
                    business across{" "}
                    <span style={{ color: "#013B6A" }}>40+ digital</span>{" "}
                    services.
                  </Paragraph>
                </div>
              </Col>
              
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-center align-items-center">
                <img
                  src={"./assets/images/img26.png"}
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                  }}
                  />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="cus-card-box" gutter={[25, 25]}>
        {cards?.map((item, i) => {
          return (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              className=""
              key={i}
              style={{
                marginBottom: "20px",
              }}
              >
              <Card hoverable>
                <div className="icon">
                  <Icon icon={item.icon} width={28}></Icon>
                </div>
                <h4 className="mt-5">{item.title}</h4>
                <p className="mb-0 mt-3">
                  <Meta description={item.description} />
                </p>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
              </Wrapper>
  );
}
