import React from 'react'
import { Wrapper } from './style'
import { Avatar, Button, Card, Col, ConfigProvider, Empty, List, Pagination, Row, Table, Tag, Typography } from 'antd';
import { Icon } from '@iconify/react/dist/iconify.js';
import { primaryColor } from '../../../config';
import { Link } from 'react-router-dom';


const { Meta } = Card;
const { Title, Paragraph } = Typography;

function RegisteredAgent() {

    const dataSource = [{
        state: 'Illinois',
        agentName:'Ibrahem Abourahma',	
        agentAddress:'13120 Georgetown Drive Palos Park IL 60462',	
        renewalDate:'06/02/2025',
        status:'Completed',
        
    }];
      
      const getStatusTag = (status) => {
        const statusColors = {
          Completed: 'green',
          Pending: 'orange',
          Cancelled: 'red',
        };
        return <Tag color={statusColors[status] || 'default'}>{status}</Tag>;
      };

      const columns = [
        {
          title: 'State',
          dataIndex: 'state',
          key: 'state',
        },
        {
          title: 'Agent Name',
          dataIndex: 'agentName',
          key: 'agentName',
          render: (_ , records) => <div className='fw-bold fs-7'>{records.agentName}</div>,
        },
        {
            title: 'Agent Address',
            dataIndex: 'agentAddress',
            key: 'agentAddress',
          },
        {
          title: 'Renewal Date',
          dataIndex: 'renewalDate',
          key: 'renewalDate',
        },
       
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status) => getStatusTag(status),
        },
      ];

      
      const data = [
        {
          icon: <Icon icon='solar:user-plus-line-duotone'></Icon>,
          title: "New Registered Agent Service",
          description: "Use to set up a new registered agent service for a new state",
          pageLink:'/order-register-agent'
        },
        {
          icon: <Icon icon='solar:user-check-rounded-line-duotone'></Icon>,
          title: "Change Registered Agent",
          description: "Use to update the registered agent on file with the state of formation.",
          pageLink:'/change-register-agent'
        },
  
      ];


  return (
      <Wrapper>
      <Card title={<>
        <div>Registered Agent</div>
        <div style={{fontWeight:400,fontSize:12,}}>What is the role of a <b style={{color:primaryColor}}>Registered Agent?</b></div>
        </>}
      extra={<> </>      }
      >
         <div className="table-responsive">
         <Table
            columns={columns}
            dataSource={dataSource}
            rowKey="key"
            scroll={{ x: 'max-content' }}
            pagination={false}
            locale={{
              emptyText: (
                <Empty></Empty>
              ),
            }}
          />
        </div>
      </Card>
      <Card className='mt-2 pgi-custom'>
      <ConfigProvider
        theme={{
          components: {
            Pagination: {
              itemActiveBg: '#025497', // Active page background
              itemActiveColor: '#000', // Active page background
              itemBg: '#f5f5f5', // Background of all items
              itemActiveColorDisabled: 'rgba(0, 0, 0, 0.25)', // Text color for disabled active
              itemActiveBgDisabled: 'rgba(0, 0, 0, 0.15)', // Background for disabled active
              itemSize: 33, // Custom size of pagination items
              itemSizeSM: 30, // Custom size for small pagination
              itemInputBg: '#f5f5f5', // Input background color
            },
          },
        }}
      >
    <Pagination
      total={75}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      defaultPageSize={20}
      defaultCurrent={1}
    />
  </ConfigProvider>
      </Card>
        
      <Row className='cus-card-box mt-7' gutter={[25, 25]} >
                {data.map((item, index) => (
                    <Col xs={24} sm={12} md={8} lg={9} key={index}>
                    <Card hoverable>
                        <div >{item.icon}</div>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        <div className='mt-5'>
                        <Link to={item?.pageLink} className='d-flex align-items-center'>
                            <Button type="primary" size='large' className='px-5'>Order <Icon color='#fff' icon='solar:arrow-right-outline' width={18}></Icon></Button>
                        </Link>
                        </div>
                    </Card>
                    </Col>
                ))}
            </Row>

    </Wrapper>
  )
}

export default RegisteredAgent