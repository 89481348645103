import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Collapse, Flex, Row, Typography } from "antd";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

export default function Payments() {
  const data = [
    {
      description:
        "Grow your contact list with the Text Us website widget and connect with customers using their preferred method of communication—two-way text messaging. Our platform ensures compliance and security while keeping costs low.",
    },
    {
      description:
        "Leverage SMS templates to maintain consistency and professionalism across staff text communications. Reduce manual follow-ups with automated SMS surveys, reminders, and bulk campaigns on important offers.",
    },
    {
      description:
        "Track metrics from our detailed dashboards. Track open rates, link clickthrough rates, in/outbound message response speeds and more.",
    },
  ];

  const data2 = [
    {
      description:
        "Pay by Text and email links help you get paid faster. Streamline your bulk billing with batch Pay by Text invoicing and personalized SMS reminders.",
    },
    {
      description:
        "Allow customers to choose from diverse payment options such as credit card, ACH bank transfer, or direct text payments, with 0% credit card processing fees.",
    },
    {
      description:
        "Allow customers to choose from diverse payment options such as credit card, ACH bank transfer, or direct text payments, with 0% credit card processing fees.",
    },
  ];
  return (
    <Wrapper>

    <div>
      <Row className="mb-10 mt-5" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row gutter={[25, 25]}>
              <Col  xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                >
                  <Icon className="me-2" icon="solar:card-broken" width={22} />{" "}
                  Bizee Payments
                </Title>
                <Title
                  className="mb-0"
                  level={2}
                  style={{ fontWeight: "bold" }}
                  >
                  Unlock Exclusive Savings with Bizee's Texting and Payment
                  Solutions
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Explore Bizee's engagement and payments platform, powered by
                  Everyware. This all-in-one platform combines cost-effective,
                  secure text messaging features with a range of billing and
                  payment processing options to streamline your business
                  operations and enhance customer interactions.
                </Paragraph>
                <Button type="primary" size="large" className="px-10">
                  SEE A DEMO
                </Button>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items-center main-img">
                <img
                  src={"./assets/images/img32.png"}
                  style={{
                    height: "350px",
                  }}
                  />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          marginTop: "30px",
          marginBottom: "70px",
        }}
      >
        <div
          style={{
            alignContent: "center",
            textAlign: "center",
          }}
          >
          <Title
            style={{
              fontWeight: "bold",
              marginTop: "30px",
            }}
            level={3}
            >
            Text Engagement Essentials
          </Title>
          <span
            style={{ fontSize: "17px", color: "#999", textAlign: "center" }}
            >
            With a 98% open rate on text messages, ensure your messages are
            noticed and acted upon promptly.
          </span>
        </div>
        <div style={{ marginBottom: "30px", marginTop: "30px" }}>
          <Row className="cus-card-box" gutter={[25, 25]}>
            {data?.map((item, i) => {
              return (
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  className=""
                  key={i}
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <Card hoverable>
                    <div className="icon">
                      <Icon
                        icon={"solar:check-circle-broken"}
                        width={40}
                        color="#013B6A"
                      ></Icon>
                    </div>

                    <Paragraph
                      style={{
                        fontWeight: "500",
                        marginTop: "20px",
                        fontSize: "16px",
                      }}
                    >
                      {item.description}
                    </Paragraph>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div
        style={{
          marginTop: "30px",
          marginBottom: "70px",
        }}
      >
        <div
          style={{
            alignContent: "center",
            textAlign: "center",
          }}
          >
          <Title
            style={{
              fontWeight: "bold",
              marginTop: "30px",
            }}
            level={3}
            >
            Billing and Payment Essentials
          </Title>
          <span
            style={{
              fontSize: "17px",
              color: "#999",
              textAlign: "center",
              lineHeight: "23px",
            }}
            >
            Empower your customers with the flexibility to pay how they want,
            when they want.
            <br />
            Our robust platform was designed specifically to support and grow
            startups like yourssupports in-person,
            <br />
            online, and mobile payments, all with the convenience of 0% credit
            card processing fees.
          </span>
        </div>
        <div style={{ marginBottom: "30px", marginTop: "40px" }}>
          <Row className="cus-card-box" gutter={[25, 25]}>
            {data2?.map((item, i) => {
              return (
                <Col
                xs={24}
                sm={12}
                  md={12}
                  lg={8}
                  className=""
                  key={i}
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <Card hoverable>
                    <div className="icon">
                      <Icon
                        icon={"solar:check-circle-broken"}
                        width={40}
                        color="#013B6A"
                        ></Icon>
                    </div>

                    <Paragraph
                      style={{
                        fontWeight: "500",
                        marginTop: "20px",
                        fontSize: "16px",
                      }}
                      >
                      {item.description}
                    </Paragraph>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <Row className="mb-10" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row gutter={[25, 25]}
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
              >
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex jutify-content-start align-items-center child-img">
                <img
                  src={"./assets/images/img33.png"}
                  style={{
                    height: "200px",
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title className="mb-5" level={2}>
                  Top Benefits of Pay by Text
                </Title>
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Experience the unmatched convenience of our Pay by Text
                  invoicing features, designed to make billing as effortless as
                  possible.
                </Paragraph>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>
                    <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                      Customers appreciate the simplicity and speed of a secure
                      invoice link texted from a business they trust.
                    </Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>
                    <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                      Apply your company's branding to invoices and send to
                      customers from your designated, 10DLC registered messaging
                      number matched to your local area code.
                    </Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>
                    <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                      Built-in, personalized invoice reminders decrease the
                      effort required to manage receivables.
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
    </Wrapper>
  );
}
