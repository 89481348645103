import React from "react";
import { Card, Button, Typography, List, Badge } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import "antd/dist/reset.css";

const { Title, Text } = Typography;

const filings = [
  {
    title: "File Annual Report",
    description:
      "The majority of states require that companies file periodic reports that affirm the current information of the company's members, directors, and business address.",
    lastFiled: "Last Filed: 05/28/2024",
    action: "File",
  },
  {
    title: "Submit your 90-Day BOI Report (US Government required)",
    description:
      "The federal government requires you to submit a BOIR (Beneficial Ownership Information Report) within 90 days of starting your business. We'll help you do it fast and easy.",
    note: "*There has been an injunction for this requirement as of December 3rd, we continue to advise our clients to file as the litigation is still pending in the courts.",
    action: "File",
  },
  {
    title: "Amendment",
    description: "Filed if a company requires changes to membership, addresses, or company name.",
    action: "File",
  },
  {
    title: "Foreign Qualification",
    description: "Filed when you need to expand your entity to new states.",
    action: "File",
  },
  {
    title: "Assumed Business Name",
    description: "Filed if a company requires an assumed business/fictitious name.",
    action: "File",
  },
  {
    title: "Certificate of Good Standing",
    description:
      "Required by governmental and private agencies to validate a company's status in order to facilitate specified transactions.",
    action: "File",
  },
  {
    title: "Form 2553",
    description:
      "The 2553 is the IRS form filed by a business entity in order to obtain the S-Corporation tax classification.",
    action: "File",
  },
  {
    title: "Virtual Address",
    description:
      "In order to assist clients in maintaining the privacy of their personal addresses, we offer a business address that can be used on the articles of organization.",
    action: "File",
  },
  {
    title: "Reinstatement",
    description:
      "This is a required submission when you are reinstating your business to 'Good Standing' with the state it was formed in.",
    action: "File",
  },
  {
    title: "Dissolution",
    description:
      "This is a required submission detailing the dissolution of your company with the state.",
    action: "File",
  },
  {
    title: "EIN / Tax ID Number",
    description:
      "Take steps to protect your brand and valuable company assets such as your name, logo, and tagline.",
    lastFiled: "Filed: 06/16/2023",
    action: "View Document",
  },
];

const StateAndIRSFilings = () => {
  return (
    <div >
      <Card style={{ marginBottom: 16 }}>
        <Title level={4}>State & IRS Filings</Title>
        <Text>
          We can assist with protecting your good standing by filing various
          mandatory state and IRS requirements.
        </Text>
      </Card>
      <Card className="mb-5" style={{ backgroundColor: "rgb(2 84 151)", color: "#fff" }}>
        <Title className="mb-0" level={4} style={{ color: "#fff" }}>
          Easily manage your state filings with one subscription.
        </Title>
      </Card>
      <List
        itemLayout="vertical"
        dataSource={filings}
        renderItem={(item) => (
          <Card style={{ marginBottom: 16 }}>
            <List.Item>
              <List.Item.Meta
                title={<Title level={5}>{item.title}</Title>}
                description={<Text>{item.description}</Text>}
              />
              {item.note && <Text type="danger">{item.note}</Text>}
              {item.lastFiled && <Text type="secondary">{item.lastFiled}</Text>}
              <Button type="primary" className="px-5" size="large" style={{ float: "right" }}>
                {item.action}
              </Button>
            </List.Item>
          </Card>
        )}
      />
    </div>
  );
};
export default StateAndIRSFilings