import styled from "styled-components";

export const Wrapper = styled.div`

.main-img img{
height:250px;
}
@media (max-width: 768px) {
.main-img{
justify-content:center !important;
}
.main-img img{
height: 100%;
width:100%;
}
}
`;