import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Flex, Row, Typography } from "antd";
import React from "react";

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;

export default function taxes() {
  return (
    <div>
      <Row className="mb-10" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " >
            <Row gutter={[20,20]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                >
                  <Icon
                    className="me-2"
                    icon="iconoir:percentage-circle"
                    width={22}
                  />{" "}
                  Bizee Tax
                </Title>
                <Title className="mb-0" level={2}>
                  Breaking Down Business Taxes
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  A solid strategy can save you stress, money and, most
                  importantly, your sanity.
                </Paragraph>

                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Whether your DIY or do-it-for-me, we break down the biggest
                  tax questions and stumbling blocks for business owners.
                </Paragraph>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items-center main-img">
                <img
                  src={"./assets/images/img7.png"}
                  className="w-100"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div>
        <Title level={3} style={{ marginBottom: "5px" }}>
          Which Tax Solution is Right for your Business?
        </Title>
        <Paragraph
          style={{ fontSize: "15px", fontWeight: "lighter", opacity: 0.9 }}
        >
          There are different ways to approach your business taxes. What's
          yours?
        </Paragraph>
          <Row gutter={[20,20]} >
          <Col xs={24} sm={24} md={12} lg={12} >
            <Card
              style={{
                borderRadius: 10,
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                border: "1px solid #f0f0f0",
              }}
             
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src="./assets/images/img8.svg"
                  alt="Bizee Logo"
                  style={{ height: 30 }}
                  />
                <span
                  style={{
                    backgroundColor: "#E3F5FF",
                    color: "#1890ff",
                    padding: "5px 20px",
                    borderRadius: 15,
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  31 Days Free Trial
                </span>
              </div>
              <div
                style={{ borderTop: "1px solid #f0f0f0", margin: "20px 0" }}
              ></div>
              <div style={{ marginTop: 20 }}>
                <Title level={4}>Bizee Tax and Bookkeeping Service</Title>
                <Text style={{ color: "#666" }}>
                  There are different ways to approach your business taxes.
                  What’s yours?
                </Text>
                <Button
                  type="primary"
                  block
                  style={{
                    marginTop: 15,
                  }}
                  size="large"
                >
                  SCHEDULE FREE CONSULTATION
                </Button>
              </div>

              <div
                style={{ borderTop: "1px solid #f0f0f0", margin: "20px 0" }}
                ></div>

              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: 15,
                  borderRadius: 8,
                }}
              >
                <Title level={5} style={{ marginBottom: 10 }}>
                  Recommended for:
                </Title>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>LLC's, S-Corp, C-Corp's with:</Col>
                </Row>
                <ul style={{ paddingLeft: 50, color: "#666" }}>
                  <li>
                    Employees, property, operating in more than 1 state, with
                    multiple accounts or multiple income streams
                  </li>
                </ul>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>
                    Businesses of any size, where tax management, compliance,
                    and financial reporting are vital
                  </Col>
                </Row>
              </div>

              {/* Show Details Link */}
              <div style={{ textAlign: "center", marginTop: 15 }}>
                <a href="#" color="primary" style={{ fontWeight: "bold" }}>
                  Show Details
                </a>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} >
            <Card
              style={{
                borderRadius: 10,
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                border: "1px solid #f0f0f0",
               height: window.innerWidth < 768 ? "600px":"508px"
              }}
              
              >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                >
                <img
                  src="./assets/images/img9.svg"
                  alt="Bizee Logo"
                  style={{ height: 30 }}
                />
                <span
                  style={{
                    backgroundColor: "#E3F5FF",
                    color: "#1890ff",
                    padding: "5px 20px",
                    borderRadius: 15,
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  7 Days Free Trial
                </span>
              </div>
              <div
                style={{ borderTop: "1px solid #f0f0f0", margin: "20px 0" }}
              ></div>
              <div style={{ marginTop: 20 }}>
                <Title level={4}>Keeper Tax and Expense Tracking</Title>
                <Text style={{ color: "#666" }}>
                  Expense Tracking and Taxes in one app!
                </Text>
                <Button
                  type="primary"
                  block
                  style={{
                    marginTop: 15,
                  }}
                  size="large"
                  >
                  TRY KEEPER TAX FOR FREE
                </Button>
              </div>

              <div
                style={{ borderTop: "1px solid #f0f0f0", margin: "20px 0" }}
              ></div>

              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: 15,
                  borderRadius: 8,
                }}
                >
                <Title level={5} style={{ marginBottom: 10 }}>
                  Recommended for:
                </Title>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>Single-member LLCs</Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Icon className="me-3" icon="ic:round-check" width={22} />
                  </Col>
                  <Col sm={20}>
                    All new businesses uncertain of their tax strategy
                  </Col>
                </Row>
              </div>

              {/* Show Details Link */}
              <div style={{ textAlign: "center", marginTop: 15 }}>
                <a href="#" color="primary" style={{ fontWeight: "bold" }}>
                  Show Details
                </a>
              </div>
            </Card>
          </Col>
      </Row>
      </div>
    </div>
  );
}
