import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Collapse, Flex, Row, Typography } from "antd";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

export default function googleMyBusiness() {
  return (
    <Wrapper>

    <div className="mt-5">
      <Text
        style={{
          fontSize: "15px",
          fontWeight: "bold",
        }}
      >
        <img src={"./assets/images/img18.jpg"} />
        {"  "}What you can do with Google My Business
      </Text>
      <Row className="mb-10 mt-5" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row gutter={[25, 25]}>
              
              <Col xs={24} sm={24} md={12} lg={12}>
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <img src={"./assets/images/img16.jpg"} />
                </div>
                <Title
                  className="mb-0"
                  level={2}
                  style={{ fontWeight: "bold" }}
                >
                  Attract new customers with your free business listing on
                  Google Search and Google Maps.
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  Your listing appears right when people are searching for your
                  business or businesses like yours on Google Search and Maps.
                </Paragraph>
                <Button type="primary" size="large" className="px-10">
                  PROCEED{" "}
                  <Icon
                    color="#fff"
                    icon="solar:arrow-right-outline"
                    width={20}
                    ></Icon>
                </Button>
              </Col>
              
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items main-img">
                <img
                  src={"./assets/images/img17.jpg"}
               
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mb-10" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row
              
              gutter={[25, 25]}
              >
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-cetner align-items-center main-img">
                <img
                  src={"./assets/images/img19.jpg"}
                 
                  />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title className="mb-5" level={2}>
                  Stand out on Google with a free Business Profile
                </Title>
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  More than just a local listing, your free Business Profile
                  lets you manage how your business appears on Google Search and
                  Maps.
                </Paragraph>
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  The world searches on Google. Turn those searches into
                  customers.
                </Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          alignContent: "center",
          textAlign: "center",
          marginTop: "70px",
          marginBottom: "50px",
        }}
      >
        <img src={"./assets/images/img20.jpg"} />
        <Title
          style={{
            fontWeight: "bold",
            marginTop: "30px",
            marginBottom: "30px",
          }}
          level={2}
        >
          Start managing your Business Profile on Google today
        </Title>
        <Button type="primary" size="large" className="px-10 py-6">
          PROCEED{" "}
          <Icon color="#fff" icon="solar:arrow-right-outline" width={20}></Icon>
        </Button>
      </div>
    </div>
            </Wrapper>
  );
}
