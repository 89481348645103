import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Flex, Input, Row, Typography } from "antd";
import React from "react";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

export default function businessFormationKit() {
  return (
    <div>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} >
          <Card className="box-info " style={{ overflow: "hidden" }}>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                >
                  <Icon
                    className="me-2"
                    icon="mingcute:basket-line"
                    width={22}
                  />{" "}
                  Business Formation Kit
                </Title>
                <Title className="mb-0" level={2}>
                  Get your Business Formation KIT
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  The Business Formation Kit is a professional binder enclosed
                  in a matching slip case, customized with the name of your
                  company on the spine insert.
                </Paragraph>
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  It comes with a metal die-cast corporate embossing seal with
                  its own carrying pouch, customized with the name of your
                  company and the date and state of formation. It has a set of
                  Mylar Reinforced Index Tabs, 20 custom printed stock or
                  membership certificates with 20 full page stubs.
                </Paragraph>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-center align-items-center">
                <img
                  src={"./assets/images/img21.png"}
                 className="w-100"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          alignContent: "center",
          textAlign: "center",
          marginTop: "70px",
          marginBottom: "50px",
        }}
      >
        <Title
          style={{
            fontWeight: "bold",
            marginBottom: "10px",
          }}
          level={3}
        >
          Unboxing Your Business Formation Kit
        </Title>
        <Paragraph style={{ color: "#999" }}>
          Your official state documents will be delivered in a high quality and
          professional kit with your company name stamped along the spine of the
          book
        </Paragraph>
        <img
          src={"./assets/images/img22.png"}
          style={{
            // height: "50vh",
            width: "100%",
          }}
        />
        <Title
          style={{
            fontWeight: "bold",
            marginBottom: "10px",
          }}
          level={3}
        >
          Get yours for <span style={{ color: "#015497" }}>$99</span> only
        </Title>
        <Button type="primary" size="large" className="px-10 py-6 mt-5">
          GET YOUR BUSINESS FORMATION KIT
        </Button>
      </div>
    </div>
  );
}
