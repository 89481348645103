import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Card, Col, Flex, Input, Row, Typography } from "antd";
import React from "react";
import { Wrapper } from "./style";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

export default function domainEmail() {
  return (
    <Wrapper>

    <div>
      <Row className="mb-7" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info ">
            <Row guutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title
                  className="d-flex align-items-center "
                  style={{ color: "#025497" }}
                  level={5}
                >
                  <Icon
                    className="me-2"
                    icon="icon-park-outline:protect"
                    width={22}
                  />{" "}
                  Domain Name & Email
                </Title>
                <Title className="mb-0" level={2}>
                  Increase Your Credibility with a Business Email Address
                </Title>
                <div style={{ marginTop: "20px" }} />
                <Paragraph style={{ opacity: ".6", fontSize: "17px" }}>
                  You are eligible to receive a{" "}
                  <span style={{ fontWeight: "bold" }}>FREE email box</span>{" "}
                  with your custom domain service. Your brand is about to get a
                  huge update! First, search for a domain:
                </Paragraph>
                <Flex>
                  <Input
                    size="large"
                    placeholder="Enter domain name"
                    prefix={
                      <Icon
                        className="me-2"
                        icon="material-symbols-light:search-rounded"
                        width={22}
                      />
                    }
                    style={{ marginRight: "10px" }}
                  />

                  <Button size="large" type="primary">
                    CHECK DOMAIN
                  </Button>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex justify-content-end align-items-center mt-7 mt-md-0 mt-lg-0 main-img"> 
                <img
                  src={"./assets/images/img1.png"}
                 
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info ">
            <Row gutter={[16, 20]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title className="mb-0" level={2}>
                  Why Do You Need a Domain?
                </Title>
                <Flex style={{ marginTop: "30px" }}>
                  <div style={{ marginRight: "10px" }}>
                    <Icon
                      icon="material-symbols:check-rounded"
                      width={22}
                      style={{ color: "#025497" }}
                      />
                  </div>
                  <div class="flex flex-col">
                    <Title level={5} style={{ marginBottom: "2px" }}>
                      Be a business they can trust
                    </Title>
                    <Paragraph style={{ opacity: 0.6 }}>
                      You'd be surprised how much a professional email and
                      online security help establish you as a reputable brand.
                    </Paragraph>
                  </div>
                </Flex>
                <Flex style={{ marginTop: "10px" }}>
                  <div style={{ marginRight: "10px" }}>
                    <Icon
                      icon="material-symbols:check-rounded"
                      width={22}
                      style={{ color: "#025497" }}
                      />
                  </div>
                  <div class="flex flex-col">
                    <Title level={5} style={{ marginBottom: "2px" }}>
                      Create a lasting impression
                    </Title>
                    <Paragraph style={{ opacity: 0.6 }}>
                      Whether it's a beautifully designed website, or how well
                      you communicate using it, your customers will notice.
                    </Paragraph>
                  </div>
                </Flex>
                <Flex style={{ marginTop: "10px" }}>
                  <div style={{ marginRight: "10px" }}>
                    <Icon
                      icon="material-symbols:check-rounded"
                      width={22}
                      style={{ color: "#025497" }}
                    />
                  </div>
                  <div class="flex flex-col">
                    <Title level={5} style={{ marginBottom: "2px" }}>
                      Enjoy the journey
                    </Title>
                    <Paragraph style={{ opacity: 0.6 }}>
                      Building a business requires patience. With the right
                      tools and guidance, growth is just around the corner.
                    </Paragraph>
                  </div>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="d-flex  justify-content-end  align-items-center main-img">
                <img
                  src={"./assets/images/img2.png"}
                  
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mb-5" gutter={[25, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card className="box-info ">
            <Row>
              <Title className="mb-0" level={2}>
                What Can You Do with Bizee’s Domain & Email Service?
              </Title>
            </Row>
            <br />
            <Row justify={"space-around"}>
              <Card
                hoverable
                style={{ width: 300 }}
                cover={<img alt="example" src="./assets/images/img3.png" />}
                >
                <Meta
                  title="Domain Name"
                  description="Build credibility by developing a web presence to describe your products and services."
                />
              </Card>
              <Card
                hoverable
                style={{ width: 300 }}
                cover={<img alt="example" src="./assets/images/img4.png" />}
                >
                <Meta
                  title="Business Card"
                  description="Build brand consistency with every new person you meet by having a professional email."
                />
              </Card>
              <Card
                hoverable
                style={{ width: 300 }}
                cover={<img alt="example" src="./assets/images/img5.png" />}
              >
                <Meta
                  title="Email Signature"
                  description="Create a cohesive brand experience with every message you send."
                  />
              </Card>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} className="">
          <Card>
            <Title className="mb-5" level={2}>
              Your Domains and Emails
            </Title>

            {/* Centered Card */}
            <div
              style={{
                backgroundColor: "black",
              }}
              >
              <div
              className="domain-name"
                
                >
                <img
                  src="./assets/images/img6.png"
                  style={{
                    width: "150px",
                    marginBottom: "10px",
                  }}
                />
                <Title level={4}>Claim Your Domain Today</Title>
                <Paragraph style={{ opacity: 0.6 }}>
                  You are eligible to receive a <b>FREE email box</b> with your
                  custom domain service. Your brand is about to get a huge
                  update! First, search for a domain.
                </Paragraph>
                <Button
                  type="primary"
                  size="large"
                  //   style={{ width: "100%", background: "#FF5722" }}
                  >
                  FIND A DOMAIN NAME
                </Button>
              </div>
              <img
                src="./assets/images/img2.png"
                style={{
                  height: "350px",
                  width: "100%",
                  marginTop: "20px",
                  filter: "blur(3px)", // Slight blur effect
                }}
                />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
                </Wrapper>  
  );
}
