import styled from "styled-components";

export const Wrapper = styled.div`
.main-img img{
height: 380px;
}




@media  (min-width: 992px) and (max-width: 1199px) {
    .main-img img{
height: 100%;
width:100%;
}
.btn-learn{
padding: 7px 4px;
font-size:12px !important;
}
}


@media  (min-width: 768px) and (max-width: 991px) {
  .main-img img{
height: 100%;
width:100%;
}
.btn-learn{
padding: 7px 4px;
font-size:12px !important;
}
}

@media (max-width: 768px) {
.main-img{
jusify-content:center;
}
.main-img img{
height: 100%;
width:100%;
}
}
`;