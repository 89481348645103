import styled from "styled-components";

export const Wrapper = styled.div`

.main-img img{
height:300px;
width:300px
}
@media  (min-width: 768px) and (max-width: 991px) {
  .main-img img{
height: 100%;
width:100%;
}
}
  @media (max-width: 768px) {
    .main-img {
      justify-content: center !important;
    }
      .main-img img{
height:100%;
width:100%;
}
  }
`;
