import styled from "styled-components";

export const Wrapper = styled.div`
  .cus-card-box {
  }
  .cus-card-box .icon svg {
    color: #025497;
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  .cus-card-box h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .cus-card-box p {
    opacity: 0.75;
  }
  .cus-card-box a {
    color: #025497;
    font-weight: 500;
  }
  .cus-card-box a svg {
    width: 22px;
    height: 22px;
    margin-bottom: 0;
    margin-left: 5px;
  }
  .ant-card .ant-card-meta-description {
    color: rgb(0 0 0 / 89%);
  }
  .ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgb(0 0 0);
  }
  .ant-card-meta {
    min-height: 70px;
  }
  .ant-card .ant-card-actions {
    border-top: 1px dashed #efeaea;
  }

  .box-info ul li {
    line-height: 32px;
    display: flex;
    align-items: center;
  }
  .box-info ul {
    list-style: none;
    padding-left: 0;
    font-size: 15px;
  }

  .box-info {
    background-image: linear-gradient(356deg, #013b6a00, #0f5d9c3b) !important;
    min-height: 240px;
  }
  .seprator {
    border-bottom: 1px dashed #d9d9d9;
  }
    .ant-collapse-header{align-items:center}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    padding: 24px;
        align-items: center;
}
        .ant-collapse .ant-collapse-content {
    border-top: 1px dashed #d9d9d9;
}

.complianc-img img{
    width: 340px;
    border-bottom-right-radius: 17px;
}

@media  (min-width:768px) and (max-width:991px) {
.complianc-img img{width: 100%;}
}
@media (max-width: 768px) {
.complianc-img{
justify-content:center;
}
    .complianc-img img{width: 100%;}
}
`;