import React, { useState } from 'react'
import { Alert, Avatar, Button, Card, Col, Flex, List, Radio, Row, Select, Typography, Form, Input } from 'antd'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { primaryColor } from '../../../../config';
import { WrapperInner } from './style';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;
const { Option } = Select;
const { Meta } = Card;

  
  const notices = [
    {
      key: 1,
      icon:<Icon width={27} color={primaryColor} icon='solar:shield-check-line-duotone'></Icon>,
      title: "Maintain business compliance.",
      description: "Trusted by over 1,00,000 business owners to maintain their state's business compliance obligations.",
    },
    {
      key: 2,
      icon:<Icon width={27} color={primaryColor} icon='solar:dollar-minimalistic-line-duotone'></Icon>,
      title: "Tax savings benefit",
      description: "This is a fully deductible business expense.",
    },

  ];


function ChangeRegisterAgent() {

    const [selectedState, setSelectedState] = useState(null);
  const [changeAgent, setChangeAgent] = useState('individual');

console.log(changeAgent, 'changeAgent')

  return (
    <WrapperInner>
        <Link to='/registered-agent'>
            <Button color='default' size='large' variant='text' className='px-0' icon={<Icon icon='solar:arrow-left-linear' width={20}></Icon>}> Back to Registered Agent</Button>
        </Link>

        <Row className='mt-5' gutter={[26,16]}>
            <Col xs={24} sm={24} md={7} lg={7} className=''>
            <Card style={{position:'sticky', top:100}}>
              <Flex align='center' justify='space-between' className='mb-7 px-5 py-3' style={{background:'rgb(18 95 158 / 12%)',border:'1px dashed #0f5d9c75', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius:'10px'}} wrap>
                <Title className='  mb-0 text-center' level={5}>Order Summary</Title>
                <Title className='  mb-0 mt-0 text-center fw-bolder' style={{color:primaryColor}} level={3}>$125</Title>
              </Flex>
                <Flex className='mt-4' align='center' justify="space-between">
                <div className='fs-7'>IL State Fee</div>
                <div className='fs-7 fw-bolder'>$26</div>
            </Flex>
                <Flex className='mt-4' align='center' justify="space-between">
                    <div className='fs-7'>Processing Fee</div>
                    <div className='fs-7 fw-bolder'>$99</div>
                 </Flex>
                <div className='seprator my-3'></div>
                 <Flex className='' align='center' justify="space-between">
                    <div className='fs-7'>Registered Agent Fee</div>
                    <div className='fs-7 fw-bolder'>$125</div>
                 </Flex>

                 <div className='seprator my-3'></div>


              <List
            dataSource={notices}
            className="mt-5"
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.icon}
                  title={<Text strong>{item.title}</Text>}
                  description={item.description}
                />
              </List.Item>
            )}
          />
            </Card>
          </Col>


            <Col xs={24} sm={24} md={16} lg={16} className=''>

            <Card className='mb-5' title={<><div style={{fontWeight:400,fontSize:12,}}>Current associate company:</div><div>IYSHIPPING LLC</div></>}>

                <Row style={{alignItems:'center'}} gutter={[26,16]}>
                    <Col xs={24} sm={24} md={12} lg={6} > 
                    Entity Type: <Text strong>LLC</Text> 
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} >
                    State of Formation: <Text strong>Illinois</Text> 
                    </Col>
                    <Col className='d-flex align-items-center  justify-content-between' xs={24} sm={24} md={12} lg={12} >
                    <Text strong>State Requiring RA:</Text> 
                    <Select
                    placeholder="Select State"
                    style={{ width: 150, marginLeft: 10 }}
                    onChange={value => setSelectedState(value)}
                    >
                    <Option value="california">California</Option>
                    <Option value="texas">Texas</Option>
                    </Select>
                    </Col>
                </Row>
                <Card className='box-info mt-5'>
            <Flex align="center" justify="space-between">
              <Meta
              className=' d-flex align-items-center'
                avatar={
                  <Avatar
                    style={{ backgroundColor: "rgb(218 230 241)" }}
                    size={55}
                    icon={
                      <Icon
                        icon="solar:lightbulb-line-duotone"
                        style={{ color: primaryColor }}
                        width={27}
                      ></Icon>
                    }
                  />
                }
                // title="Virtual Mailbox and Mail Scanning Service"
                description={
                  <span className='' >
                  The <b style={{color:'#000'}}>state of formation</b> is where the company was formed, while the <b style={{color:'#000'}}>state of service</b> is where you are seeking to obtain authority to transact business.
                  </span>
                }
              />
              <>
                
              </>
            </Flex>
                </Card>

            </Card>

            <Card className='mb-5' title="Current Agent">
            Ibrahem Abourahma, 13120 Georgetown Drive, Palos Park, Illinois, 60462
            </Card>
              
            <Card title="Provide name & address of newly appointed Registered Agent">
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item className='mb-3' label="">
                    <Radio.Group onChange={e => setChangeAgent(e.target.value)} defaultValue="individual">
                      <Radio className='rdi-btn' value={"individual"}>Individual</Radio>
                      <Radio className='rdi-btn mt-3' value={"company"}>Company</Radio>
                  </Radio.Group>
                  </Form.Item>
                </Col>
                {changeAgent === 'individual' && (
                  <>
                  <Col span={12}>
                    <Form.Item label="Agent First Name" className='fw-bold mb-1' name="firstName" rules={[{ required: true, message: "First Name is required" }]}> 
                      <Input size='large' className='form-control' placeholder="First Name" />
                    </Form.Item>
                  </Col>    

                  <Col span={12}>
                    <Form.Item label="Agent Last Name" className='fw-bold mb-1' name="lastName" rules={[{ required: true, message: "Last Name is required" }]}> 
                      <Input size='large' className='form-control' placeholder="Last Name" />
                    </Form.Item>
                  </Col></>

              )}

                {changeAgent === 'company' && (
                   <Col span={24}>
                     <Form.Item label="Company Name*" className='fw-bold mb-1' name="companyName" rules={[{ required: true, message: "Company Name is required" }]}> 
                       <Input size='large' className='form-control' placeholder="Company Name" />
                     </Form.Item>
                   </Col>    
              )}
                  

                  <Col span={12}>
                    <Form.Item label="Agent Street" className='fw-bold mb-1' name="street" rules={[{ required: true, message: "Street is required" }]}> 
                      <Input size='large' className='form-control' placeholder="Street 1" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Address (Cont)" className='fw-bold mb-1' name="street2"> 
                      <Input size='large' className='form-control' placeholder="Street 2" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="City" name="city" className='fw-bold mb-1' rules={[{ required: true, message: "City is required" }]}> 
                      <Input size='large' className='form-control' placeholder="City" />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="State" className='fw-bold mb-1' name="state" rules={[{ required: true }]}> 
                      <Select size='large' className='' defaultValue="Illinois">
                        <Option value="Illinois">Illinois</Option>
                        <Option value="New York">New York</Option>
                        <Option value="California">California</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Zip Code" className='fw-bold mb-1' name="zipCode" rules={[{ required: true, message: "Zip Code is required" }]}> 
                      <Input size='large' className='form-control' placeholder="Zip Code" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

  
        </Row>
    </WrapperInner>
  )
}

export default ChangeRegisterAgent