import styled from "styled-components";

export const Wrapper = styled.div`
.main-img img{
height: 100%;
width:100%;
border-radius:20px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
color:black;
}


.selected-column {
  background-color:#0254971f !important;
  border-left:1px solid #025497;
  border-right:1px solid #025497 !important;
  border-top:1px solid #025497;
  border-bottom:1px solid #025497;
 
  }
  
  .ant-table-wrapper .ant-table-thead >tr>th.ant-table-cell.selected-column {
   border-top-left-radius:${(props) => (props.selectedColumn  ? "15px" :0)};;
  border-top-right-radius:${(props) => (props.selectedColumn  ? "15px" :0)};;
  }

  .ant-table-wrapper .ant-table-tbody tr:last-child td.ant-table-cell.selected-column {
    border-bottom: ${(props) => (props.selectedColumn  ? "1px solid #025497" : "unset")};
    border-bottom-left-radius: ${(props) => (props.selectedColumn  ? "15px" : "0")};
    border-bottom-right-radius: ${(props) => (props.selectedColumn  ? "15px" : "0")};
  }

.selected-btn{

  border:1px solid #025497;
}
  .recommended{
  text-align: center;
    padding: 6px;
    position: absolute;
    z-index: 1;
    top: 0%;
    background: #0254971f;
    width: 100%;
    left: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
  }

`;