import styled from "styled-components";

export const Wrapper = styled.div`
@media  (min-width: 992px) and (max-width: 1199px) {
    .main-img img{
height: 100%;
width:100%;
}
}
@media  (min-width: 768px) and (max-width: 991px) {
  .main-img img{
height: 100%;
width:100%;
}
}
@media (max-width: 768px) {
.main-img img{
height:250px;
}
.main-img{
justify-content:center !important;
}
.main-img img{
height: 100%;
width:100%;
}
}
`;