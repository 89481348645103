import styled from "styled-components";

export const Wrapper = styled.div`
 .cus-card-box{}
    .cus-card-box .icon svg{color:#025497;width:50px;height:50px;margin-bottom:15px}
    .cus-card-box h3{font-size:18px;    margin-bottom: 15px;}
    .cus-card-box p{opacity:0.75}
    .cus-card-box a{color:#025497;font-weight:500}
    .cus-card-box a svg{width:22px;height:22px;margin-bottom:0;margin-left:5px}
    .ant-card .ant-card-meta-description {color: rgb(0 0 0 / 89%);}
    .ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {color: rgb(0 0 0);}
    .ant-card-meta {min-height: 20px;}
    .ant-card .ant-card-actions {border-top: 1px dashed #efeaea;}

    .box-info {
    background-image: linear-gradient(356deg, #013b6a00, #0f5d9c3b) !important;
    min-height: 240px;}

    .list-step .ant-list-item-meta-avatar{display:flex}
    .list-step .ant-list-item-meta-title{margin-bottom:0px !important}
    .ant-list-split .ant-list-item {border-block-end: 1px dashed rgb(5 5 5 / 12%);}


    .mailbox-img img{
    width: 360px;
   border-bottom-right-radius: 17px;
    }
   .ant-card .ant-card-meta-detail{
      width:100%;
   }

  
   @media  (min-width: 768px) and (max-width:991px) {
   .mailbox-img img{width: 100%;}
   }
    @media (max-width: 768px) {
    .mailbox-img{
    justify-content:center
    }
      .mailbox-img img{width: 100%;}
    
      .ant-card .ant-card-meta-detail{
      width:163px;
   }
}
    `;