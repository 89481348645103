// action.js

export const setData = (data) => ({
    type: "SET_DATA",
    payload: data,
  });
  
  export const setSessionData = (data) => ({
    type: "SESSION_DATA",
    payload: data,
  });
  






