import styled from "styled-components";

export const Wrapper = styled.div`
     .cus-card-box{}
    .cus-card-box svg{color:#025497;width:50px;height:50px;margin-bottom:15px}
    .cus-card-box h3{font-size:18px;}
    .cus-card-box p{opacity:0.75}
    .cus-card-box a{color:#025497;font-weight:500}
    .cus-card-box a svg{width:22px;height:22px;margin-bottom:0;margin-left:5px}

`;