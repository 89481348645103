import styled from "styled-components";

export const Wrapper = styled.div`
    // border:1px solid blue;
    .ant-input-affix-wrapper{display:flex}
    .form-control{height:43px}

     @media (max-width: 767.98px) {
        .ant-card-head{padding: 10px 24px !important}
    }
`;