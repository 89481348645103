import styled from "styled-components";

export const Wrapper = styled.div`
@media  (min-width: 992px) and (max-width: 1199px) {
   
.btn-learn{
padding: 7px 4px;
font-size:12px !important;
}
}


@media  (min-width: 768px) and (max-width: 991px) {
 
.btn-learn{
padding: 7px 4px;
font-size:12px !important;
}
}
@media (max-width: 768px) {
.main-img{
justify-content:center !important;
}
.main-img img{
height: 100%;
width:100%;
}
}
`;