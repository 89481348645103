import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Flex,
  Input,
  Progress,
  Row,
  Select,
  Typography,
  Radio,
  Layout,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MakeApiCall } from "../../../apis";

const { Option } = Select;

const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const progress = {
  1: 25,
  2: 30,
  3: 35,
  4: 37,
  5: 40,
  6: 45,
  7: 55,
  8: 58,
  9: 65,
  10: 70,
  11: 85,
};

const faqs = [
  {
    question: "What if company name is unavailable?",
    answer:
      "In the event the company name is unavailable, we will contact you to request an alternative company name. We will continue to work with you until we find a name that is accepted by the state.",
  },
  {
    question: `Does the company name end with "LLC" or "INC"?`,
    answer:
      "Incorporated entities require the use of a designator to signify that the company is conducting business as an incorporated entity. Every state has different allowable designators. The most typical designator for the Limited Liability Company is 'LLC' and for Corporations it is 'INC'",
  },
];

const ourAgent = [
  {
    title: "Free First Year",
    description:
      "Every new order for incorporation service includes 1 year of FREE Registered Agent service. Service automatically renews each year, but you may cancel at any time by changing your agent and then contacting us. View Terms",
  },
  {
    title: "Guaranteed Rates",
    description:
      "After the first year your renewal rate will remain $119 per year and is guaranteed for the life of the service. When your renewal is due you will be notified and be allowed to decide if renewing the service is in your best interests.",
  },
  {
    title: "All-Inclusive",
    description:
      "We never charge a dime in additional fees for postage paid to deliver you your company's important documents.",
  },
  {
    title: "Reduce Junk Mail",
    description:
      "Many companies will acquire distribution lists of new companies and use the registered agent address to send annoying solicitation mail. By having a registered agent address you can reduce the amount of junk mail received.",
  },
];

export default function Setup() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [haveAgent, setHaveAgent] = useState(true);
  const [emailApplication, setEmailApplication] = useState(null);
  const [haveSecurityNumber, setHaveSecurityNumber] = useState(false);
  const [interested, setInterested] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState("ssn");
  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [designators, setDesignators] = useState([]);
  const [selectedDesignator, setSelectedDesignator] = useState({});

  const [contactPersonFirstName, setContactPersonFirstName] = useState("");
  const [contactPersonLastName, setContactPersonLastName] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");
  const [consent, setConsent] = useState(true);

  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const [companyAddress, setCompanyAddress] = useState("");
  const [companyAddressCount, setCompanyAddressCount] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companySelectedCountry, setCompanySelectedCountry] = useState("");
  const [companySelectedState, setCompanySelectedState] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");

  const [virtualAddress, setVirtualAddress] = useState({});

  const [combos, setCombos] = useState({});

  const [numberOfPeoples, setNumberOfPeoples] = useState(null);
  const [memberData, setMemberData] = useState([]);

  const [agentFirstName, setAgentFirstName] = useState("");
  const [agentLastName, setAgentLastName] = useState("");
  const [agentAddress, setAgentAddress] = useState("");
  const [agentAddressCount, setAgentAddressCount] = useState("");
  const [agentCity, setAgentCity] = useState("");
  const [agentSelectedState, setAgentSelectedState] = useState("");
  const [agentZipCode, setAgentZipCode] = useState("");

  const [addressType, setAddressType] = useState(1);
  const [info, setInfo] = useState({});
  const [waiting, setWaiting] = useState(false);
  const client = searchParams.get("client");

  useEffect(() => {
    if (!searchParams.get("client")) {
      navigate(`/start-new-company?client=${client}`);
    }
  }, [searchParams]);

  useEffect(() => {
    getCountries();
    getStates();
  }, []);

  useEffect(() => {
    if (client) {
      getInfo();
    }
  }, [client]);

  useEffect(() => {
    if (info?.id) {
      if (step === 1) {
        getDesignators();
      } else if (step === 3) {
        getVirtualAddress();
      } else if (step === 4) {
        getCombos();
      }
    }
  }, [step, info]);

  const getStates = async () => {
    const response = await MakeApiCall(
      `api/v1/dashboard/states/?limit=100`,
      "get"
    );
    if (response.status) {
      setStates(response.data);
    } else {
      message.error("this api are not fetxh");
    }
  };

  const getCountries = async () => {
    const response = await MakeApiCall(
      `api/v1/dashboard/country/?limit=500`,
      "get"
    );
    if (response.status) {
      setCountries(response.data);
    } else {
      message.error("this api are not fetxh");
    }
  };

  const getInfo = async () => {
    const response = await MakeApiCall(
      `api/v1/business/create/${client}/`,
      "get"
    );
    if (response.status) {
      setInfo(response);
      if (response?.name) {
        setCompanyName(response?.name);
      }

      if (response?.next_step) {
        setStep(parseInt(response?.next_step));
      }

      if (response?.user) {
        setContactPersonFirstName(response?.user?.first_name);
        setContactPersonLastName(response?.user?.last_name);
        setContactPersonEmail(response?.user?.email);
        setContactPersonPhone(response?.user?.phone_number);
      }

      if (response?.business_address) {
        setCompanyAddress(response?.business_address?.street_address);
        setCompanyAddressCount(response?.business_address?.address);
        setCompanyCity(response?.business_address?.city);
        setCompanyZipCode(response?.business_address?.zip_code);
        setCompanySelectedState(response?.business_address?.state);
        setCompanySelectedCountry(response?.business_address?.country);
        setAddressType(response?.business_address?.virtual_mail_service?.id);
      }
    } else {
      message.error(response.message);
    }
  };

  const getDesignators = async () => {
    const response = await MakeApiCall(
      `api/v1/dashboard/designators/?entity_type__name=${info?.entity_type}&state__name=${info?.state?.name}`,
      "get"
    );
    if (response.status) {
      setDesignators(response.data);
      if (info?.designator) {
        setSelectedDesignator({
          id: info?.designator_id,
          name: info?.designator,
        });
      } else {
        setSelectedDesignator(response.data[0]);
      }
    } else {
      message.error(response.message);
    }
  };

  const getVirtualAddress = async () => {
    const response = await MakeApiCall(
      `api/v1/virtual-mailboxes/${info?.state?.name}`,
      "get"
    );
    if (response.status) {
      setVirtualAddress(response);
    } else {
      message.error(response.message);
    }
  };

  const getCombos = async () => {
    const response = await MakeApiCall(
      `api/v1/dashboard/premium-service-combos/${client}`,
      "get"
    );
    if (response.status) {
      setCombos(response);
    } else {
      message.error(response.message);
    }
  };

  const onNext = () => {
    if (step === 1) {
      step1();
    } else if (step === 2) {
      step2();
    } else if (step === 3) {
      step3();
    } else if (step === 4) {
      step4();
    } else if (step === 5) {
      step5();
    } else if (step === 6) {
      step6();
    } else {
      setStep(step + 1);
    }
  };

  const step1 = async () => {
    if (!companyName || !selectedDesignator?.id) {
      if (!companyName) {
        message.error("Please enter company name");
      } else {
        message.error("Please enter company name");
      }
    } else {
      setWaiting(true);
      const body = {
        client_ip: client,
        name: companyName.toUpperCase(), //upper case
        designator: selectedDesignator?.id,
        next_step: step + 1,
      };
      const response = await MakeApiCall(
        `api/v1/business/create/information/`,
        "post",
        body
      );
      setWaiting(false);
      if (response.status) {
        setStep(step + 1);
      } else {
        message.error(response.message);
      }
    }
  };

  const step2 = async () => {
    if (
      !contactPersonFirstName ||
      !contactPersonLastName ||
      !contactPersonEmail ||
      !contactPersonPhone ||
      contactPersonPhone.length !== 10
    ) {
      if (!contactPersonFirstName) {
        message.error("Please enter first name.");
      } else if (!contactPersonLastName) {
        message.error("Please enter last name.");
      } else if (!contactPersonEmail) {
        message.error("Please enter email.");
      } else if (!contactPersonPhone) {
        message.error("Please enter phone number.");
      } else if (contactPersonPhone.length !== 10) {
        message.error("Please enter valid phone number.");
      }
    } else {
      setWaiting(true);
      const body = {
        client_ip: client,
        first_name: contactPersonFirstName,
        last_name: contactPersonLastName,
        email: contactPersonEmail,
        phone_number: contactPersonPhone,
        concent: consent,
        next_step: step + 1,
      };
      const response = await MakeApiCall(
        `api/v1/business/create/contact-person/`,
        "post",
        body
      );
      setWaiting(false);
      if (response.status) {
        setStep(step + 1);
      } else {
        message.error(response.message);
      }
    }
  };

  const step3 = async () => {
    if (
      !companyAddress ||
      !companyCity ||
      !companySelectedState?.id ||
      !companyZipCode ||
      !companySelectedCountry?.id
    ) {
      if (!companyAddress) {
        message.error("Please enter company address");
      } else if (!companyCity) {
        message.error("Please enter city of your company");
      } else if (!companySelectedState?.id) {
        message.error("Please select state of your company");
      } else if (!companyZipCode) {
        message.error("Please enter zipcode of your company");
      } else if (!companySelectedCountry?.id) {
        message.error("Please select country of your company");
      }
    } else {
      setWaiting(true);
      const body = {
        client_ip: client,
        virtual_mail_service: addressType,
        street_address: companyAddress,
        address: companyAddressCount,
        city: companyCity,
        state: companySelectedState?.id,
        country: companySelectedCountry?.id,
        zip_code: companyZipCode,
        next_step: step + 1,
      };
      const response = await MakeApiCall(
        `api/v1/business/create/address-information/`,
        "post",
        body
      );
      setWaiting(false);
      if (response.status) {
        setStep(step + 1);
      } else {
        message.error(response.message);
      }
    }
  };

  const step4 = async (skip = false) => {
    if (!combos?.id && !skip) {
      message.error("No packages is selected");
    } else {
      setWaiting(true);
      const body = {
        client_ip: client,
        premium_service_combos: skip ? null : combos?.id,
        next_step: step + 1,
      };
      const response = await MakeApiCall(
        `api/v1/business/create/premium-service-package/`,
        "post",
        body
      );
      setWaiting(false);
      if (response.status) {
        setStep(step + 1);
      } else {
        message.error(response.message);
      }
    }
  };

  const step5 = async () => {
    const validateMembers = (memberData) => {
      for (let index = 0; index < memberData.length; index++) {
        let member = memberData[index];

        if (member.type === "company" && !member.company_name) {
          return `Company name is required for Member ${index + 1}`;
        }
        if (member.type === "individual") {
          if (!member.first_name) {
            return `First name is required for Member ${index + 1}`;
          }
          if (!member.last_name) {
            return `Last name is required for Member ${index + 1}`;
          }
        }
        if (!member.street_address) {
          return `Street address is required for Member ${index + 1}`;
        }
        if (!member.city) {
          return `City is required for Member ${index + 1}`;
        }
        if (!member.state) {
          return `State is required for Member ${index + 1}`;
        }
        if (!member.zip_code) {
          return `Zip code is required for Member ${index + 1}`;
        }
        if (
          member.ownership === undefined ||
          member.ownership === null ||
          member.ownership === ""
        ) {
          return `Ownership is required for Member ${index + 1}`;
        }
      }

      return null; // No errors found
    };

    // Usage
    const error = validateMembers(memberData);
    if (error) {
      message.error(error); // Show the first error message and stop further checks
    } else {
      setWaiting(true);
      const body = {
        client_ip: client,
        team_data: {
          members: memberData,
        },
        next_step: step + 1,
      };
      const response = await MakeApiCall(
        `api/v1/business/create/premium-service-package/`,
        "post",
        body
      );
      setWaiting(false);
      if (response.status) {
        setStep(step + 1);
      } else {
        message.error(response.message);
      }
    }
  };

  const step6 = async () => {
    if (
      !agentFirstName ||
      !agentLastName ||
      !agentAddress ||
      !agentAddressCount ||
      !agentCity ||
      !agentSelectedState ||
      !agentZipCode
    ) {
      if (!agentFirstName) {
        message.error("Please enter agent first name");
      } else if (!agentLastName) {
        message.error("Please enter agent last name");
      } else if (!agentAddress) {
        message.error("Please enter address of agent");
      } else if (!agentCity) {
        message.error("Please enter city of agent");
      } else if (!agentSelectedState) {
        message.error("Please select state of agent");
      } else if (!companyZipCode) {
        message.error("Please enter zipcode of agent");
      }
    } else {
      alert("coming soon");
      return;

      setWaiting(true);
      const body = {
        client_ip: client,
        is_system_agent: haveAgent,
        next_step: step + 1,
      };

      // if(!haveAgent){
      //   body.agent_choices = 1;
      //   body.first_name = agentFirstName;
      //   body.agent_last_name = agentLastName;
      //   body.agent_street_address = agentAddress;
      //   body.agent_address = agentAddressCount;
      //   body.agent_city = agentCity;
      //   body.agent_state = agentSelectedState;
      //   body.agent_zip_code = agentZipCode;
      // }

      const response = await MakeApiCall(
        `api/v1/business/create/process-agent/`,
        "post",
        body
      );
      setWaiting(false);
      if (response.status) {
        setStep(step + 1);
      } else {
        message.error(response.message);
      }
    }
  };

  const changeMemberData = (index, value) => {
    const newData = [...memberData];
    newData[index] = { ...newData[index], ...value };
    setMemberData(newData);
  };

  return (
    <Layout>
      <Content style={{ padding: "50px" }}>
        <div>
          <Row className="mb-10 mt-5" gutter={[25, 25]}>
            <Col xs={24} sm={24} md={16} lg={15}>
              <div
                style={{
                  marginBottom: "20px",
                  marginLeft: "5px",
                }}
              >
                <Progress percent={progress[step]} />
              </div>
              <Card className="box-info " style={{ overflow: "hidden" }}>
                {step === 1 && (
                  <div>
                    <Title level={4}>Company Information</Title>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        margin: "20px 0px",
                      }}
                    />
                    <Flex gap={10}>
                      <div style={{ width: "50%" }}>
                        <label>{info?.entity_type} Name</label>
                        <Input
                          placeholder="Company Name"
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value.toUpperCase());
                          }}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>Designator</label>
                        <Select
                          value={selectedDesignator?.id}
                          style={{ width: "100%" }}
                          size="large"
                          suffixIcon={<span style={{ color: "#000" }}>▼</span>}
                          onChange={(value, option) => {
                            setSelectedDesignator(option.obj);
                          }}
                        >
                          {designators?.map((option, index) => (
                            <Option key={index} value={option?.id} obj={option}>
                              {option?.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Flex>
                    {companyName && (
                      <div style={{ marginTop: "20px" }}>
                        <label style={{ fontWeight: "500" }}>
                          Your official company name will display as
                        </label>
                        <div
                          style={{
                            padding: "10px 15px",
                            backgroundColor: "#F0FBFE",
                            borderColor: "#CDF0FF",
                            fontSize: "17px",
                            borderRadius: "10px",
                            marginTop: "10px",
                            display: "flex",
                            textTransform: "uppercase",
                          }}
                        >
                          {companyName} {selectedDesignator?.name}
                          <span style={{ marginLeft: "auto" }}>
                            <Icon
                              icon={"ic:baseline-check"}
                              width={20}
                              color="#013B6A"
                            ></Icon>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <Title level={4}>Contact Person</Title>
                    <Paragraph>
                      Please provide the name of the person responsible for this
                      order whom we may contact if additional information is
                      needed.
                    </Paragraph>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        margin: "20px 0px",
                      }}
                    />
                    <Flex gap={10}>
                      <div style={{ width: "50%" }}>
                        <label>First Name</label>
                        <Input
                          placeholder="John"
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={contactPersonFirstName}
                          onChange={(e) => {
                            setContactPersonFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>Last Name</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={contactPersonLastName}
                          onChange={(e) => {
                            setContactPersonLastName(e.target.value);
                          }}
                        />
                      </div>
                    </Flex>
                    <Flex gap={10} style={{ marginTop: "20px" }}>
                      <div style={{ width: "50%" }}>
                        <label>Email</label>
                        <Input
                          placeholder="john@gmail.com"
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={contactPersonEmail}
                          onChange={(e) => {
                            setContactPersonEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>Mobile Phone</label>
                        <Input
                          placeholder="000000000"
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          maxLength={10}
                          inputMode="numeric"
                          value={contactPersonPhone}
                          onChange={(e) => {
                            setContactPersonPhone(e.target.value);
                          }}
                        />
                        <div style={{ marginTop: "10px" }}>
                          <Checkbox
                            style={{ marginRight: "10px" }}
                            checked={consent}
                            onChange={(e) => {
                              setConsent(e.target.checked);
                            }}
                          />
                          I consent to receiving SMS text messages and phone
                          calls from EzLaw.
                        </div>
                      </div>
                    </Flex>
                  </div>
                )}
                {step === 3 && (
                  <div>
                    <Title level={4}>Company Address Information</Title>

                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        margin: "20px 0px",
                      }}
                    />
                    <Paragraph>
                      We recommend using our Virtual Address service if
                      maintaining your personal privacy is of importance. The
                      State of {info?.state?.name} formation documents collect
                      and make publicly available both the business address of
                      the entity as well as the personal addresses of the
                      owners.
                    </Paragraph>
                    <div>
                      <b>Benefits of Using a Private Virtual Mail Address</b>
                      <div style={{ marginBottom: "10px" }} />
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>
                          Keeping your personal address confidential
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>
                          Real-time text and email notification of any incoming
                          mail
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>
                          Maintaining a physical presence, even if you’re not
                          physically there
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>
                          Permanent digital access to your mail anywhere in the
                          world
                        </Col>
                      </Row>
                    </div>
                    {/* <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    flexWrap:"wrap",
                  }}
                > */}
                    <Row gutter={[20, 20]} style={{ marginTop: "20px" }}>
                      {virtualAddress?.id && (
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <div
                            style={{
                              border:
                                addressType === 1
                                  ? "2px dotted  #013B6A"
                                  : "1px solid #999",
                              borderRadius: "10px",
                              padding: "15px 20px",
                              cursor: "pointer",
                              // width: "49%",
                              position: "relative",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              setAddressType(virtualAddress?.id);
                            }}
                          >
                            <span
                              style={{
                                fontSize: "13px",
                                fontWeight: "500",
                                backgroundColor: "#013B6A",
                                padding: "3px 10px",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                            >
                              Privacy
                            </span>
                            <br />
                            <img
                              src={"./assets/images/img34.svg"}
                              style={{
                                height: "80px",
                                marginTop: "10px",
                                marginBottom: "30px",
                              }}
                            />
                            <br />
                            <span
                              style={{ fontSize: "17px", fontWeight: "500" }}
                            >
                              Professional Business Address
                              <br />& Virtual Mail Service
                            </span>
                            <div
                              style={{
                                border: "1px dashed #ccc",
                                margin: "10px 0px",
                              }}
                            />
                            <span>
                              This will be your principal company address:
                            </span>
                            <div
                              style={{
                                backgroundColor: "white",
                                border: "2px dashed #5382B4",
                                fontSize: "17px",
                                fontWeight: "bold",
                                borderRadius: "10px",
                                padding: "10px 30px",
                                marginTop: "10px",
                              }}
                            >
                              {virtualAddress?.address_line1}
                              {virtualAddress?.address_line2}
                            </div>
                            <small>
                              Suite # assigned upon order completion
                            </small>
                            <div
                              style={{
                                border: "0.5px dashed #ccc",
                                margin: "10px 0px",
                              }}
                            />
                            <div>
                              <Row>
                                <Col sm={3}>
                                  <Icon
                                    className="me-3"
                                    icon="ic:round-check"
                                    width={22}
                                  />
                                </Col>
                                <Col sm={20} style={{ textAlign: "left" }}>
                                  Maintain privacy by using a commercial address
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <Icon
                                    className="me-3"
                                    icon="ic:round-check"
                                    width={22}
                                  />
                                </Col>
                                <Col sm={20} style={{ textAlign: "left" }}>
                                  Unlimited scanned incoming mail
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <Icon
                                    className="me-3"
                                    icon="ic:round-check"
                                    width={22}
                                  />
                                </Col>
                                <Col sm={20} style={{ textAlign: "left" }}>
                                  A physical address for your business (not PO
                                  Box)
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <Icon
                                    className="me-3"
                                    icon="ic:round-check"
                                    width={22}
                                  />
                                </Col>
                                <Col sm={20} style={{ textAlign: "left" }}>
                                  Instant alerts with 24/7 access to your mail
                                  online
                                </Col>
                              </Row>
                            </div>
                            <div
                              style={{
                                border: "0.5px dashed #ccc",
                                margin: "10px 0px",
                              }}
                            />
                            <span>
                              <span
                                style={{ fontSize: "30px", fontWeight: "500" }}
                              >
                                ${virtualAddress?.price}
                              </span>
                              /month
                            </span>
                            <br />
                            <b>Cancel anytime.</b>
                            <br />
                            <span>First month fee due at checkout</span>
                            <Button
                              size="large"
                              style={{ marginTop: "20px", width: "100%" }}
                              type={addressType === 1 ? "primary" : "default"}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  icon={"ei:check"}
                                  width={25}
                                  style={{ marginRight: "5px" }}
                                />
                                Yes, I’d like to use Virtual Address
                              </div>
                            </Button>
                          </div>
                        </Col>
                      )}
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <div
                          style={{
                            border:
                              addressType === null
                                ? "2px dotted  #013B6A"
                                : "1px solid #999",
                            borderRadius: "10px",
                            padding: "15px 20px",
                            cursor: "pointer",
                            // width: "49%",
                            position: "relative",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setAddressType(null);
                          }}
                        >
                          <img
                            src={"./assets/images/img35.svg"}
                            style={{
                              height: "80px",
                              marginTop: "30px",
                              marginBottom: "30px",
                            }}
                          />
                          <br />
                          <span style={{ fontSize: "17px", fontWeight: "500" }}>
                            Use My Own Address
                          </span>
                          <div
                            style={{
                              border: "1px dashed #ccc",
                              margin: "10px 0px",
                            }}
                          />
                          <p>
                            I will provide my own {info?.state?.name} business
                            address and will personally keep up with the
                            incoming mail.
                          </p>
                          <span>
                            {info?.state?.name} requires a physical street
                            address
                          </span>
                          <p>(P.O Boxes are not accepted).</p>
                          <p>
                            Any residential address provided to the state will
                            be listed publicly.
                          </p>
                          <Button
                            size="large"
                            style={{ marginTop: "20px", width: "100%" }}
                            type={addressType === null ? "primary" : "default"}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <Icon
                                icon={"ei:check"}
                                width={25}
                                style={{ marginRight: "5px" }}
                              />
                              No, I’d like to use my own address
                            </div>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {/* </div> */}
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        margin: "20px 0px",
                      }}
                    />
                    <Title level={4}>Company Address</Title>
                    <Flex gap={10}>
                      <div style={{ width: "70%" }}>
                        <label>Street Address</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={companyAddress}
                          onChange={(e) => {
                            setCompanyAddress(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "30%" }}>
                        <label>Address (Cont) (Optional)</label>
                        <Input
                          placeholder=""
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={companyAddressCount}
                          onChange={(e) => {
                            setCompanyAddressCount(e.target.value);
                          }}
                        />
                      </div>
                    </Flex>
                    <br />
                    <Flex gap={10}>
                      <div style={{ width: "33%" }}>
                        <label>City</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={companyCity}
                          onChange={(e) => {
                            setCompanyCity(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "33%" }}>
                        <label>State</label>
                        <Select
                          value={companySelectedState?.id}
                          style={{ width: "100%" }}
                          size="large"
                          onChange={(_, option) => {
                            setCompanySelectedState(option.obj);
                          }}
                          suffixIcon={<span style={{ color: "#000" }}>▼</span>}
                        >
                          {states.map((option, i) => (
                            <Option key={i} value={option?.id} obj={option}>
                              {option?.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ width: "33%" }}>
                        <label>Zip Code</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                          value={companyZipCode}
                          onChange={(e) => {
                            setCompanyZipCode(e.target.value);
                          }}
                        />
                      </div>
                    </Flex>
                    <br />
                    <Flex gap={10}>
                      <div style={{ width: "50%" }}>
                        <label>County</label>
                        <Select
                          value={companySelectedCountry?.id}
                          style={{ width: "100%" }}
                          size="large"
                          onChange={(_, option) => {
                            setCompanySelectedCountry(option.obj);
                          }}
                          suffixIcon={<span style={{ color: "#000" }}>▼</span>}
                        >
                          {countries.map((option, i) => (
                            <Option key={i} value={option?.id} obj={option}>
                              {option?.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Flex>
                  </div>
                )}
                {step === 4 && (
                  <div>
                    <Title level={4}>Premium Service Package</Title>
                    <span>
                      Upgrade for only ${combos?.discounted_total} to receive
                      these {combos?.package?.length} premium services — up to $
                      {combos?.sub_total} value!
                    </span>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <Row gutter={[20, 20]}>
                      {combos?.package?.map((item, i) => {
                        return (
                          <Col xs={24} sm={24} md={24} lg={12} key={i}>
                            <div
                              style={{
                                // width: "50%",
                                border: "1px solid #ccc",
                                borderRadius: "10px",
                                padding: "10px 10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item?.image}
                                  style={{
                                    height: "50px",
                                  }}
                                />
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px",
                                    borderLeftColor: "#ccc",
                                    borderLeftStyle: "solid",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <b>{item.package_name}</b>
                                    <span
                                      style={{
                                        textDecorationLine: "line-through",
                                      }}
                                    >
                                      ${item.package_price}
                                    </span>
                                    <span>${item.discounted_price}</span>
                                  </div>
                                  <span>{item.description}</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>

                    <div style={{ marginTop: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Subtotal:</span>
                        <b>${combos.sub_total}</b>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Package Discount:</span>
                        <b>-${combos.package_discount}</b>
                      </div>
                      <div
                        style={{
                          height: "1px",
                          backgroundColor: "#999",
                          margin: "10px 0px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>Package Total:</h3>
                        <h3>${combos.discounted_total}</h3>
                      </div>
                    </div>
                  </div>
                )}
                {step === 5 && (
                  <div>
                    <Title level={4}>
                      Please provide Members Information for {companyName}{" "}
                      {selectedDesignator?.name}
                    </Title>

                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <Row>
                      <label>Number of Members/Owners</label>
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        suffixIcon={<span style={{ color: "#000" }}>▼</span>}
                        onChange={(value) => {
                          setNumberOfPeoples(value);
                          let data = [];
                          Array.from({ length: value }).map((item, i) => {
                            data = [
                              ...data,
                              {
                                type: "individual",
                                first_name: "",
                                last_name: "",
                                company_name: "",
                                street_address: "",
                                city: "",
                                state: "",
                                zip_code: "",
                                ownership: "",
                              },
                            ];
                          });
                          setMemberData(data);
                        }}
                      >
                        <Option value={""} disabled>
                          Select number of members
                        </Option>
                        {["1", "2", "3", "4", 5, 6].map((option) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Row>

                    {memberData.map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            borderTop: "0.5px solid #ccc",
                            paddingTop: "20px",
                            marginTop: "20px",
                          }}
                        >
                          <Title level={4}>Member {i + 1}</Title>
                          {/* <Flex gap={10}> */}
                          <Row gutter={[20, 20]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <div
                                onClick={() =>
                                  changeMemberData(i, { type: "individual" })
                                }
                                style={{
                                  border:
                                    item.type === "individual"
                                      ? "2px solid #013B6A"
                                      : "1px solid #d9d9d9",
                                  borderRadius: "8px",
                                  padding: "12px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  // width: "50%",
                                }}
                              >
                                <Radio
                                  value="individual"
                                  style={{
                                    color: "#000",
                                    transform: "scale(1.2)",
                                    marginRight: "10px",
                                  }}
                                  checked={item.type === "individual"}
                                />
                                <div>
                                  <strong>Individual</strong>
                                  <p style={{ margin: 0, color: "#666" }}>
                                    Select if Member is a person.
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <div
                                onClick={() =>
                                  changeMemberData(i, { type: "company" })
                                }
                                style={{
                                  border:
                                    item.type === "company"
                                      ? "2px solid #013B6A"
                                      : "1px solid #d9d9d9",
                                  borderRadius: "8px",
                                  padding: "12px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  // width: "50%",
                                }}
                              >
                                <Radio
                                  value="company"
                                  style={{
                                    color: "#000",
                                    transform: "scale(1.2)",
                                    marginRight: "10px",
                                  }}
                                  checked={item.type === "company"}
                                />
                                <div>
                                  <strong>Company</strong>
                                  <p style={{ margin: 0, color: "#666" }}>
                                    Select if Member is a company.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {/* </Flex> */}
                          {item.type === "company" ? (
                            <Flex gap={10} style={{ marginTop: "50px" }}>
                              <div style={{ width: "100%" }}>
                                <label>Company Name</label>
                                <Input
                                  size="large"
                                  style={{
                                    height: "44px",
                                  }}
                                  value={item.company_name}
                                  onChange={(e) => {
                                    changeMemberData(i, {
                                      company_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Flex>
                          ) : (
                            <Flex gap={10} style={{ marginTop: "50px" }}>
                              <div style={{ width: "50%" }}>
                                <label>First Name</label>
                                <Input
                                  size="large"
                                  style={{
                                    height: "44px",
                                  }}
                                  value={item.first_name}
                                  onChange={(e) => {
                                    changeMemberData(i, {
                                      first_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div style={{ width: "50%" }}>
                                <label>Last Name</label>
                                <Input
                                  size="large"
                                  style={{
                                    height: "44px",
                                  }}
                                  value={item.last_name}
                                  onChange={(e) => {
                                    changeMemberData(i, {
                                      last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Flex>
                          )}

                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <label>Street Address</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={item.street_address}
                                onChange={(e) => {
                                  changeMemberData(i, {
                                    street_address: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <label>Address (Cont) (Optional)</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={item.address}
                                onChange={(e) => {
                                  changeMemberData(i, {
                                    address: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Flex>
                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <label>City</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={item.city}
                                onChange={(e) => {
                                  changeMemberData(i, {
                                    city: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <label>State</label>
                              <Select
                                value={item?.state}
                                style={{ width: "100%" }}
                                size="large"
                                onChange={(value) => {
                                  changeMemberData(i, {
                                    state: value,
                                  });
                                }}
                                suffixIcon={
                                  <span style={{ color: "#000" }}>▼</span>
                                }
                              >
                                {states.map((option, i) => (
                                  <Option
                                    key={i}
                                    value={option?.id}
                                    obj={option}
                                  >
                                    {option?.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </Flex>
                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <label>Zip Code </label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={item.zip_code}
                                onChange={(e) => {
                                  changeMemberData(i, {
                                    zip_code: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <label>Ownership %</label>
                              <Select
                                style={{ width: "100%" }}
                                size="large"
                                suffixIcon={
                                  <span style={{ color: "#000" }}>▼</span>
                                }
                                value={item?.ownership}
                                onChange={(value) => {
                                  changeMemberData(i, {
                                    ownership: value,
                                  });
                                }}
                              >
                                {Array.from({ length: 101 }).map(
                                  (option, i) => (
                                    <Option key={option} value={i}>
                                      {i}% Ownership
                                    </Option>
                                  )
                                )}
                              </Select>
                            </div>
                          </Flex>
                        </div>
                      );
                    })}
                  </div>
                )}
                {step === 6 && (
                  <div>
                    <Title level={4}>Registered Agent Information</Title>
                    <div>
                      <b>
                        {info?.state?.name} requires an LLC to appoint a
                        Registered Agent:
                      </b>
                      <Row style={{ marginTop: "10px" }}>
                        <Col sm={1}>
                          <Icon icon="ic:round-check" width={22} />
                        </Col>
                        <Col sm={22}>
                          Only EZlaw offers 1 full year of Registered Agent
                          service FREE with every new business formation order -
                          a $119.00 value!
                        </Col>
                      </Row>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <b>
                        Typical documents received by your Registered Agent can
                        include:
                      </b>
                      <Row style={{ marginTop: "10px" }}>
                        <Col sm={1}>
                          <Icon icon="ic:round-check" width={22} />
                        </Col>
                        <Col sm={22}>
                          Service of Process, i.e. notification of a pending
                          lawsuit or court order
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col sm={1}>
                          <Icon icon="ic:round-check" width={22} />
                        </Col>
                        <Col sm={22}>
                          State correspondence, i.e. annual reports or
                          statements
                        </Col>
                      </Row>
                    </div>
                    {/* <Flex gap={10} style={{ marginTop: "20px" }}> */}
                    <Row gutter={[20, 20]} className="mt-5">
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <div
                          onClick={() => setHaveAgent(true)}
                          style={{
                            border: haveAgent
                              ? "2px solid #013B6A"
                              : "1px solid #d9d9d9",
                            borderRadius: "8px",
                            padding: "12px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            // width: "50%",
                          }}
                        >
                          <Radio
                            value="individual"
                            style={{
                              color: "#000",
                              transform: "scale(1.2)",
                              marginRight: "10px",
                            }}
                            checked={haveAgent}
                          />
                          <div>
                            <p style={{ margin: 0, color: "#666" }}>
                              Assign EZlaw as my Registered Agent FREE For 1
                              year.
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <div
                          onClick={() => setHaveAgent(false)}
                          style={{
                            border: !haveAgent
                              ? "2px solid #013B6A"
                              : "1px solid #d9d9d9",
                            borderRadius: "8px",
                            padding: "12px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            // width: "50%",
                          }}
                        >
                          <Radio
                            style={{
                              color: "#000",
                              transform: "scale(1.2)",
                              marginRight: "10px",
                            }}
                            checked={!haveAgent}
                          />
                          <div>
                            <p style={{ margin: 0, color: "#666" }}>
                              I would like to act as my own registered agent.
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* </Flex> */}
                    <div style={{ marginTop: "20px" }}>
                      {haveAgent ? (
                        <div>
                          <Collapse
                            className="cus-accordination"
                            expandIcon={({ isActive }) => (
                              <Icon
                                icon="solar:round-alt-arrow-down-line-duotone"
                                width={20}
                                className=""
                                style={{
                                  transform: `rotate(${
                                    isActive ? "180deg" : "0deg"
                                  })`,
                                  color: "#013B6A",
                                  // marginTop: "auto",
                                  // marginBottom: "auto",
                                }}
                              ></Icon>
                            )}
                            accordion
                          >
                            {ourAgent.map((item, index) => (
                              <>
                                <Panel header={item.title} key={index}>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      color: "#434343",
                                    }}
                                  >
                                    {item.description}
                                  </p>
                                </Panel>
                                {index !== ourAgent.length - 1 && (
                                  <div className="seprator"></div>
                                )}
                              </>
                            ))}
                          </Collapse>
                        </div>
                      ) : (
                        <div style={{ marginTop: "30px" }}>
                          <Title level={4}>Agent Information</Title>
                          <div
                            style={{
                              height: "0.5px",
                              backgroundColor: "#ccc",
                              margin: "20px 0px",
                            }}
                          />
                          <p>
                            You may serve as your own Registered Agent as long
                            as you possess a physical street address{" "}
                            <b>(PO Box not allowed)</b> in the state of
                            formation.
                          </p>
                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <label>First Name</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={agentFirstName}
                                onChange={(e) => {
                                  setAgentFirstName(e.target.value);
                                }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <label>Last Name</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={agentLastName}
                                onChange={(e) => {
                                  setAgentLastName(e.target.value);
                                }}
                              />
                            </div>
                          </Flex>
                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <label>Street Address</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={agentAddress}
                                onChange={(e) => {
                                  setAgentAddress(e.target.value);
                                }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <label>Address (Cont) (Optional)</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={agentAddressCount}
                                onChange={(e) => {
                                  setAgentAddressCount(e.target.value);
                                }}
                              />
                            </div>
                          </Flex>
                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "100%" }}>
                              <label>City</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={agentCity}
                                onChange={(e) => {
                                  setAgentCity(e.target.value);
                                }}
                              />
                            </div>
                          </Flex>
                          <Flex gap={10} style={{ marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <label>State</label>
                              <Select
                                value={agentSelectedState?.id}
                                style={{ width: "100%" }}
                                size="large"
                                onChange={(value) => {
                                  setAgentSelectedState(value);
                                }}
                                suffixIcon={
                                  <span style={{ color: "#000" }}>▼</span>
                                }
                              >
                                {states.map((option, i) => (
                                  <Option key={i} value={option?.id}>
                                    {option?.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <div style={{ width: "50%" }}>
                              <label>Zip Code </label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                                value={agentZipCode}
                                onChange={(e) => {
                                  setAgentZipCode(e.target.value);
                                }}
                              />
                            </div>
                          </Flex>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {step === 7 && (
                  <div>
                    <Title level={4}>
                      EIN / Tax Identification Number Information
                    </Title>
                    <span>
                      An Employer Identification Number (EIN) is a nine-digit
                      number that is assigned by the IRS and used to identify
                      taxpayers. We will apply and obtain your EIN from the IRS
                      electronically. This option is the fastest way to obtain
                      the EIN.
                    </span>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <b>
                      I am a foreign individual and do not have a social
                      security number
                    </b>
                    <Flex gap={10} style={{ marginTop: "10px" }}>
                      <div
                        onClick={() => setHaveSecurityNumber(true)}
                        style={{
                          border: haveSecurityNumber
                            ? "2px solid #013B6A"
                            : "1px solid #d9d9d9",
                          borderRadius: "8px",
                          padding: "12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <Radio
                          value="individual"
                          style={{
                            color: "#000",
                            transform: "scale(1.2)",
                            marginRight: "10px",
                          }}
                          checked={haveAgent}
                        />
                        <div>
                          <p style={{ margin: 0, color: "#666" }}>Yes</p>
                        </div>
                      </div>

                      <div
                        onClick={() => setHaveSecurityNumber(false)}
                        style={{
                          border: !haveAgent
                            ? "2px solid #013B6A"
                            : "1px solid #d9d9d9",
                          borderRadius: "8px",
                          padding: "12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <Radio
                          style={{
                            color: "#000",
                            transform: "scale(1.2)",
                            marginRight: "10px",
                          }}
                          checked={!haveAgent}
                        />
                        <div>
                          <p style={{ margin: 0, color: "#666" }}>No</p>
                        </div>
                      </div>
                    </Flex>
                    <Flex gap={10} style={{ marginTop: "20px" }}>
                      <div style={{ width: "50%" }}>
                        <label>First Name</label>
                        <Input
                          placeholder="John"
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>Last Name</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                    </Flex>
                    <div style={{ marginTop: "20px" }}>
                      <label>
                        Identification number by which I will obtain the EIN
                      </label>
                      <Flex gap={10} style={{ marginTop: "10px" }}>
                        <div
                          onClick={() => setIdentificationNumber("ssn")}
                          style={{
                            border:
                              identificationNumber === "ssn"
                                ? "2px solid #013B6A"
                                : "1px solid #d9d9d9",
                            borderRadius: "8px",
                            padding: "12px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <Radio
                            value="individual"
                            style={{
                              color: "#000",
                              transform: "scale(1.2)",
                              marginRight: "10px",
                            }}
                            checked={identificationNumber === "ssn"}
                          />
                          <div>
                            <p style={{ margin: 0, color: "#666" }}>SSN</p>
                          </div>
                        </div>

                        <div
                          onClick={() => setIdentificationNumber("itin")}
                          style={{
                            border:
                              identificationNumber === "itin"
                                ? "2px solid #013B6A"
                                : "1px solid #d9d9d9",
                            borderRadius: "8px",
                            padding: "12px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <Radio
                            style={{
                              color: "#000",
                              transform: "scale(1.2)",
                              marginRight: "10px",
                            }}
                            checked={identificationNumber === "itin"}
                          />
                          <div>
                            <p style={{ margin: 0, color: "#666" }}>ININ</p>
                          </div>
                        </div>
                      </Flex>
                    </div>
                    <Flex gap={10} style={{ marginTop: "20px" }}>
                      <div style={{ width: "50%" }}>
                        <label style={{ textTransform: "uppercase" }}>
                          {identificationNumber}
                        </label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                    </Flex>
                    <div style={{ marginTop: "40px" }}>
                      <Collapse
                        className="cus-accordination"
                        expandIcon={({ isActive }) => (
                          <Icon
                            icon="solar:round-alt-arrow-down-line-duotone"
                            width={30}
                            className=""
                            style={{
                              transform: `rotate(${
                                isActive ? "180deg" : "0deg"
                              })`,
                              color: "#013B6A",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          ></Icon>
                        )}
                        accordion
                      >
                        <Panel
                          header={
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#1c1b18",
                              }}
                            >
                              The IRS needs your Social Security Number to
                              verify your identity and prevent fraud.
                            </span>
                          }
                        >
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#434343",
                            }}
                          >
                            In order to issue an EIN, the IRS requires a
                            Principal (typically one of the members or directors
                            of an entity) to provide their Social Security
                            Number. This creates a formal affiliation with the
                            company/entity. The Social Security Number is
                            strictly used for obtaining the EIN. Once the EIN
                            process is complete, your SSN is permanently deleted
                            from the EZlaw database. To further protect your
                            security, all Social Security Numbers are stored on
                            a secure, encrypted server during the EIN process.
                          </p>
                        </Panel>
                      </Collapse>
                    </div>
                    <div style={{ marginTop: "40px" }}>
                      <Title level={4}>Physical Street Address</Title>
                      <p>
                        The IRS requires a physical address in order to issue an
                        Employer Identification Number (EIN / Tax ID Number) to
                        your company.
                        <b>
                          {" "}
                          Please note the IRS will not allow for the use of a PO
                          Box; however, this address will not be public under
                          any circumstance.
                        </b>
                      </p>
                      <Flex gap={10}>
                        <div style={{ width: "100%" }}>
                          <label>County</label>
                          <Select
                            style={{ width: "100%" }}
                            size="large"
                            suffixIcon={
                              <span style={{ color: "#000" }}>▼</span>
                            }
                          >
                            {["County 1", "County 2", "County 3"].map(
                              (option) => (
                                <Option key={option} value={option}>
                                  {option}
                                </Option>
                              )
                            )}
                          </Select>
                        </div>
                      </Flex>
                    </div>
                    <Flex gap={10} style={{ marginTop: "20px" }}>
                      <div style={{ width: "70%" }}>
                        <label>Street Address</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                      <div style={{ width: "30%" }}>
                        <label>Address (Cont) (Optional)</label>
                        <Input
                          placeholder=""
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                    </Flex>
                    <Flex gap={10} style={{ marginTop: "20px" }}>
                      <div style={{ width: "33%" }}>
                        <label>City</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                      <div style={{ width: "33%" }}>
                        <label>State</label>
                        <Select
                          style={{ width: "100%" }}
                          size="large"
                          suffixIcon={<span style={{ color: "#000" }}>▼</span>}
                        >
                          {["State 1", "State 2", "State 3"].map((option) => (
                            <Option key={option} value={option}>
                              {option}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ width: "33%" }}>
                        <label>Zip Code</label>
                        <Input
                          size="large"
                          style={{
                            height: "44px",
                          }}
                        />
                      </div>
                    </Flex>
                  </div>
                )}
                {step === 8 && (
                  <div>
                    <Title level={4}>Setting up Small Business Banking</Title>
                    <span>No-Fee Business Banking for the Modern Business</span>
                    <br />
                    <br />
                    <b>
                      When forming a business, separating your business and
                      personal accounts is a crucial step to protecting your
                      assets
                    </b>
                    <br />
                    <br />
                    <span>
                      Relay, is an online business banking, and money management
                      platform that helps small businesses take control of their
                      cash flow and get crystal clear on what they’re earning,
                      spending, and saving.
                    </span>
                    <br />
                    <br />
                    <span>
                      *With Relay, you can apply in a few minutes, once your
                      state formation and EIN are complete.
                    </span>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <div
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        padding: "20px 20px",
                      }}
                    >
                      <Flex justify="space-between">
                        <img src={"./assets/images/img39.png"} />
                        <a>
                          <b
                            style={{
                              fontSize: "16px",
                              textDecorationLine: "underline",
                            }}
                          >
                            Disclaimer
                          </b>
                        </a>
                      </Flex>
                      <div
                        style={{
                          height: "0.5px",
                          backgroundColor: "#ccc",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      />
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>No account fees or minimums</Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>
                          Up to 20 individual checking accounts to help you
                          control projects, budgets, and taxes
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>
                          Have employees? No problem, issue up to 50 Visa debit
                          cards to keep your business moving
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Icon
                            className="me-3"
                            icon="ic:round-check"
                            width={22}
                          />
                        </Col>
                        <Col sm={20}>FDIC Insured*</Col>
                      </Row>
                    </div>

                    <div
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        padding: "20px 20px",
                        marginTop: "20px",
                      }}
                    >
                      <Flex justify="space-between">
                        <img src={"./assets/images/img40.svg"} />
                        <span
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          4 days ago
                        </span>
                      </Flex>
                      <div
                        style={{
                          height: "0.5px",
                          backgroundColor: "#ccc",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      />
                      <p>
                        “Relay has been perfect for our small business. We are
                        able to have multiple accounts to separate funds into
                        different categories and they do not charge any fees per
                        account. We are able to get physical cards for each
                        account sent to us easily as well as generate virtual
                        cards instantly for any account.”
                      </p>
                      <b>John</b>
                    </div>
                  </div>
                )}
                {step === 9 && (
                  <div>
                    <Title level={4}>Tax Strategy / Free Consultation</Title>
                    <span>
                      This consultation can help you identify important tax
                      deductions and provide insight into the IRS tax
                      classification of your new business. The offer is
                      completely free of charge and does not obligate you in any
                      way.
                    </span>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <b>What you'll learn:</b>
                    <Flex justify="space-between" style={{ marginTop: "20px" }}>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col sm={3}>
                            <Icon
                              className="me-3"
                              icon="ic:round-check"
                              width={20}
                            />
                          </Col>
                          <Col sm={20}>How your LLC is taxed</Col>
                        </Row>
                      </div>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col sm={3}>
                            <Icon
                              className="me-3"
                              icon="ic:round-check"
                              width={20}
                            />
                          </Col>
                          <Col sm={20}>Business bookkeeping requirements</Col>
                        </Row>
                      </div>
                    </Flex>
                    <Flex justify="space-between" style={{ marginTop: "10px" }}>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col sm={3}>
                            <Icon
                              className="me-3"
                              icon="ic:round-check"
                              width={20}
                            />
                          </Col>
                          <Col sm={20}>
                            How to choose the proper IRS tax election
                          </Col>
                        </Row>
                      </div>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col sm={3}>
                            <Icon
                              className="me-3"
                              icon="ic:round-check"
                              width={20}
                            />
                          </Col>
                          <Col sm={20}>
                            How to reduce the chance of an IRS audit
                          </Col>
                        </Row>
                      </div>
                    </Flex>
                    <Flex justify="space-between" style={{ marginTop: "10px" }}>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col sm={3}>
                            <Icon
                              className="me-3"
                              icon="ic:round-check"
                              width={20}
                            />
                          </Col>
                          <Col sm={20}>Commonly missed tax deductions</Col>
                        </Row>
                      </div>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col sm={3}>
                            <Icon
                              className="me-3"
                              icon="ic:round-check"
                              width={20}
                            />
                          </Col>
                          <Col sm={20}>How to reduce self employment taxes</Col>
                        </Row>
                      </div>
                    </Flex>
                    <div
                      onClick={() => setInterested(false)}
                      style={{
                        border:
                          interested === false
                            ? "1px solid #013B6A"
                            : "1px solid #d9d9d9",
                        borderRadius: "8px",
                        padding: "12px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "30px",
                      }}
                    >
                      <Radio
                        style={{
                          color: "#000",
                          transform: "scale(1.2)",
                          marginRight: "10px",
                        }}
                        checked={interested === false}
                      />
                      <span>I'm not interested at this time.</span>
                    </div>
                    <div
                      onClick={() => setInterested(true)}
                      style={{
                        border: interested
                          ? "1px solid #013B6A"
                          : "1px solid #d9d9d9",
                        borderRadius: "8px",
                        padding: "12px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Radio
                        style={{
                          color: "#000",
                          transform: "scale(1.2)",
                          marginRight: "10px",
                        }}
                        checked={interested}
                      />
                      <span>
                        Yes, I would like to receive the Business Tax
                        Consultation (FREE).
                      </span>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Collapse
                        className="cus-accordination"
                        expandIcon={({ isActive }) => (
                          <Icon
                            icon="solar:round-alt-arrow-down-line-duotone"
                            width={30}
                            className=""
                            style={{
                              transform: `rotate(${
                                isActive ? "180deg" : "0deg"
                              })`,
                              color: "#013B6A",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          ></Icon>
                        )}
                        accordion
                      >
                        <Panel
                          header={
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#1c1b18",
                              }}
                            >
                              {interested
                                ? "How soon can I schedule my Tax Consultation?"
                                : "Is a Tax Strategy / Free Consultation something I should consider?"}
                            </span>
                          }
                        >
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#434343",
                            }}
                          >
                            {interested
                              ? "You will be prompted to schedule your appointment after completing your order."
                              : "Incorporating a new business can burden owners with complicated tax filing. As a client you are entitled to receive a free no obligation consultation with a certified tax professional who can answer questions regarding the tax obligations of your company."}
                          </p>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                )}
                {step === 10 && (
                  <div>
                    <Title level={4}>Business Licenses and Permits</Title>
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <div
                      style={{
                        border: "1px solid #eee",
                        borderRadius: "10px",
                        padding: "20px 10px",
                      }}
                    >
                      {/* <Flex> */}
                      <Row gutter={[20, 20]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <img
                            src={"./assets/images/img41.png"}
                            style={{
                              height: "130px",
                              marginRight: "20px",
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <div>
                            <h5>
                              We've identified{" "}
                              <span style={{ color: "#013B6A" }}>
                                {" "}
                                2 licenses
                              </span>{" "}
                              for your business in {info?.state?.name}
                            </h5>
                            <p>
                              As a business owner, you are responsible for
                              making sure your business has the proper federal,
                              state and local licenses and permits to operate
                              legally. To make this easier:
                            </p>
                          </div>
                        </Col>
                      </Row>
                      {/* </Flex> */}
                      <div
                        style={{
                          height: "0.5px",
                          backgroundColor: "#ccc",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      />
                      {/* <Flex justify="space-between" style={{ marginTop: "20px" }}> */}
                      <Row gutter={[20, 20]} className="mt-5">
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <div
                          // style={{ width: "50%" }}
                          >
                            <Row>
                              <Col sm={3}>
                                <Icon
                                  className="me-3"
                                  icon="ic:round-check"
                                  width={20}
                                />
                              </Col>
                              <Col sm={20}>
                                Team of licensing specialists will conduct the
                                research for you
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={3}>
                                <Icon
                                  className="me-3"
                                  icon="ic:round-check"
                                  width={20}
                                />
                              </Col>
                              <Col sm={20}>
                                We’ll determine all the licenses and permits you
                                need to stay compliant
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={3}>
                                <Icon
                                  className="me-3"
                                  icon="ic:round-check"
                                  width={20}
                                />
                              </Col>
                              <Col sm={20}>
                                Receive the applications along with a guide to
                                the next steps via mail
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <div
                            style={{
                              // width: "50%",
                              paddingTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            <Flex
                              justify="center"
                              style={{ marginBottom: "20px" }}
                            >
                              <img
                                src={"./assets/images/img42.png"}
                                style={{
                                  height: "50px",
                                }}
                              />
                              <img
                                src={"./assets/images/img43.png"}
                                style={{
                                  height: "50px",
                                }}
                              />
                              <img
                                src={"./assets/images/img44.png"}
                                style={{
                                  height: "50px",
                                }}
                              />
                            </Flex>
                            <b>Join the 140,000+ business owners</b>
                            <p>
                              who have gained peace of mind with this package.
                            </p>
                          </div>
                        </Col>
                      </Row>
                      {/* </Flex> */}
                      {/* <Flex gap={10} style={{ marginTop: "10px" }}> */}
                      <Row gutter={[20, 20]} className="mt-5">
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <div
                            onClick={() => setEmailApplication(false)}
                            style={{
                              border:
                                emailApplication === false
                                  ? "2px solid #013B6A"
                                  : "1px solid #d9d9d9",
                              borderRadius: "8px",
                              padding: "12px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              // width: "50%",
                            }}
                          >
                            <Radio
                              value="individual"
                              style={{
                                color: "#000",
                                transform: "scale(1.2)",
                                marginRight: "10px",
                              }}
                              checked={emailApplication === false}
                            />
                            <span>No thanks, I'll do the work myself</span>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <div
                            onClick={() => setEmailApplication(true)}
                            style={{
                              border: emailApplication
                                ? "2px solid #013B6A"
                                : "1px solid #d9d9d9",
                              borderRadius: "8px",
                              padding: "12px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              // width: "50%",
                            }}
                          >
                            <Radio
                              style={{
                                color: "#000",
                                transform: "scale(1.2)",
                                marginRight: "10px",
                              }}
                              checked={emailApplication}
                            />
                            <span>Yes, please mail the applications ($99)</span>
                          </div>
                        </Col>
                      </Row>
                      {/* </Flex> */}
                      {emailApplication && (
                        <div style={{ marginTop: "20px" }}>
                          <p style={{ fontWeight: "600", fontSize: "16px" }}>
                            Please provide the address below where you would
                            like us to research required licenses and permits:
                          </p>
                          <Flex gap={10}>
                            <div style={{ width: "70%" }}>
                              <label>Street Address</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                              />
                            </div>
                            <div style={{ width: "30%" }}>
                              <label>Address (Cont) (Optional)</label>
                              <Input
                                placeholder=""
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                              />
                            </div>
                          </Flex>
                          <br />
                          <Flex gap={10}>
                            <div style={{ width: "33%" }}>
                              <label>City</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                              />
                            </div>
                            <div style={{ width: "33%" }}>
                              <label>State</label>
                              <Select
                                style={{ width: "100%" }}
                                size="large"
                                suffixIcon={
                                  <span style={{ color: "#000" }}>▼</span>
                                }
                              >
                                {["State 1", "State 2", "State 3"].map(
                                  (option) => (
                                    <Option key={option} value={option}>
                                      {option}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </div>
                            <div style={{ width: "33%" }}>
                              <label>Zip Code</label>
                              <Input
                                size="large"
                                style={{
                                  height: "44px",
                                }}
                              />
                            </div>
                          </Flex>
                          <br />
                          <Flex gap={10}>
                            <div style={{ width: "100%" }}>
                              <label>
                                Business Purpose (You have 100 characters left)
                              </label>
                              <Input
                                size="large"
                                placeholder="Please provide brief description of Business Purpose"
                                style={{
                                  height: "100px",
                                }}
                              />
                            </div>
                          </Flex>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    height: "0.5px",
                    backgroundColor: "#ccc",
                    marginTop: "40px",
                    marginBottom: "20px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {step > 0 && (
                    <Button
                      size="large"
                      onClick={() => {
                        if (step === 1) {
                          navigate(`/start-new-company?client=${client}`);
                        } else {
                          setStep(step - 1);
                        }
                      }}
                    >
                      <Icon icon={"formkit:left"} width={10}></Icon>
                      Back
                    </Button>
                  )}
                  <div>
                    {step === 4 && (
                      <Button
                        size="large"
                        type="default"
                        style={{ marginRight: "15px" }}
                        onClick={() => {
                          step4(true);
                        }}
                        disabled={waiting}
                      >
                        Skip
                        <Icon icon={"formkit:right"} width={10}></Icon>
                      </Button>
                    )}
                    <Button
                      size="large"
                      type="primary"
                      onClick={onNext}
                      disabled={waiting}
                    >
                      {waiting ? "Please wait..." : "Next"}
                      <Icon icon={"formkit:right"} width={10}></Icon>
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={9}>
              <Card
                style={{
                  border: "1px solid #eee",
                  borderRadius: "15px",
                }}
              >
                <Title level={4}>Order Summary</Title>
                <div
                  style={{
                    height: "0.5px",
                    backgroundColor: "#ccc",
                    margin: "20px 0px",
                  }}
                />
                <Flex style={{ marginTop: "10px" }}>
                  <span style={{ opacity: 0.7 }}>Entity Type:</span>
                  <span style={{ marginLeft: "auto" }}>
                    {info?.entity_type}
                  </span>
                </Flex>
                <Flex style={{ marginTop: "10px" }}>
                  <span style={{ opacity: 0.7 }}>
                    {info?.business_formation_package?.name} package
                  </span>
                  <span style={{ marginLeft: "auto" }}>
                    ${info?.business_formation_package?.package_price}
                  </span>
                </Flex>
                <Flex style={{ marginTop: "10px" }}>
                  <span style={{ opacity: 0.7, textTransform: "capitalize" }}>
                    {info?.state?.name} Filing Fee
                  </span>
                  <span style={{ marginLeft: "auto" }}>
                    ${info?.state?.state_fees}
                  </span>
                </Flex>
                {info?.business_formation_package?.included_services &&
                  Object.values(
                    info?.business_formation_package?.included_services
                  ).map((item, i) => {
                    return (
                      <Flex style={{ marginTop: "10px" }} key={i}>
                        <span style={{ opacity: 0.7 }}>
                          {item.service_name}
                        </span>
                        <span style={{ marginLeft: "auto" }}>
                          {item?.price_included ? (
                            <Icon
                              icon={"ic:baseline-check"}
                              width={20}
                              color="#013B6A"
                            />
                          ) : (
                            <span style={{ marginLeft: "auto" }}>
                              ${item?.service_price}
                            </span>
                          )}
                        </span>
                      </Flex>
                    );
                  })}
                <div>
                  <span>
                    Available Monday - Friday
                    <br />
                    From 9 a.m. to 6 p.m. CST
                  </span>
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "#eee",
                    margin: "20px 0px",
                  }}
                />
                <Flex>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Total:
                  </span>
                  <span
                    style={{
                      marginLeft: "auto",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    ${info?.total}
                  </span>
                </Flex>
              </Card>
            </Col>
          </Row>

          <Card>
            <Title level={5}>Additional Explanation</Title>
            <div style={{ marginTop: "30px" }}>
              <Collapse
                className="cus-accordination"
                expandIcon={({ isActive }) => (
                  <Icon
                    icon="solar:round-alt-arrow-down-line-duotone"
                    width={30}
                    className=""
                    style={{
                      transform: `rotate(${isActive ? "180deg" : "0deg"})`,
                      color: "#013B6A",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  ></Icon>
                )}
                accordion
              >
                {faqs.map((item, index) => (
                  <>
                    <Panel
                      header={
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#1c1b18",
                          }}
                        >
                          {item.question}
                        </span>
                      }
                      key={index}
                    >
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#434343",
                        }}
                      >
                        {item.answer}
                      </p>
                    </Panel>
                    {index !== faqs.length - 1 && (
                      <div className="seprator my-3"></div>
                    )}
                  </>
                ))}
              </Collapse>
            </div>
          </Card>
        </div>
      </Content>
    </Layout>
  );
}
