export const ProjectName = "Layout Demo";

export const primaryColor = "#025497";

export const ORIGIN = "https://dev-api.eazylawyer.com";
export const ENDPOINT = `${ORIGIN}/`;

export const dummyImage = "assets/images/blank.png";

export const DefaultAdminPage = `/manage-user`;
export const DefaultUserPage = `/dashboard`;

export const Types = {
  1: "admin",
  2: "user",
};
